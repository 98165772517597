import CryptoJS from 'crypto-js';
export const decryptString = (data) => {
    const bytes = CryptoJS.AES.decrypt(data, 'fcrf#@key@123');
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
     return decryptedData;
 };

 // shareIdExtractor.js
export const extractShareIdByKey=(data, keyToSearch)=> {
    if (typeof data === "string") {
      return data; // If the input is already a string, return it
    } else if (typeof data === "object" && keyToSearch) {
      if (data[keyToSearch]) {
        if (typeof data[keyToSearch] === "string") {
          return data[keyToSearch];
        } else if (data[keyToSearch][keyToSearch]) {
          return data[keyToSearch][keyToSearch];
        }
      }
    }
    return null; // Return null if the specified key is not found
  }
  