import { useState, useEffect, useContext } from "react";
import { Spinner, Button } from "react-bootstrap";
import DiamondWeight from "./diamondWeight";
import DiamondColor from "./diamondColor";
import DiamondShape from "./diamondShape";
import DiamondSaturation from "./diamondSaturation";
import DiamondClarity from "./diamondClarity";
import { withAuth } from "../../config/axios";
import { useNavigate, useLocation } from "react-router-dom";
import FcrfContext from "../../Context";
import Loader from "../loader/loader";
import "../rarity/rarity.css";
import "./rarityArgyle.css";
import { rarityHandlePayload } from "../../utils/rarityStepPayload";
import { Helmet } from "react-helmet";
import PageLoader from "../loader/pageLoader";
const RarityArgyle = ({
  showArgyleContent,
  setShowArgyleContent,
  setLoader,
  loader
}) => {
  const getContext = useContext(FcrfContext);
  const {
    steps,
    setIsWp,
    inputError,
    setInputError,
    setStepScroll,
    setBackScroll,
  } = getContext;
  const setContext = useContext(FcrfContext);
  const navigate = useNavigate();
  const location = useLocation();
  const initialState = {
    type: "",
    weight: "",
    carat: "",
    color: "",
    saturation: "",
    shape: "",
    clarity: "",
    select: "",
  };
  const [payload, setPayload] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [loaderType, setLoaderType] = useState("loginalert");
  const [isActive, setIsActive] = useState(false);
  const handleOnchange = (value, name, step) => {
    if (name === "weight" && (value < 0.1 || value > 4)) {
      setInputError(true);
      const stepStr = `?Step=${step}`;
      window.history.pushState(step, null, stepStr);
      setContext.setSetps(step);
      const newPayload = rarityHandlePayload(value, name, step, payload, from);
      setPayload(newPayload);
      return;
    }
    setInputError(false);
    const stepStr = `?Step=${step}`;
    window.history.pushState(step, null, stepStr);
    if (value) {
      const nextStep = step + 1;
      setContext.setSetps(nextStep);
    } else {
      setContext.setSetps(step);
    }
    const newPayload = rarityHandlePayload(value, name, step, payload, from);
    setPayload(newPayload);
  };

  const props = {
    payload,
    handleOnchange,
    inputError,
    setStepScroll,
  };

  const { from, data } = location?.state || {};
  useEffect(() => {
    if (from === "argyle-report") {
      setPayload(data);
    }
  }, [from, data]);

  const handleSubmit = async () => {
    setLoading(true);
    setLoaderType("progress");
    try {
      if (
        localStorage.getItem("fcrf@token") === "undefined" ||
        !localStorage.getItem("fcrf@token")
      ) {
        setLoading(true);
        return;
      }

      const response = await withAuth().post("report/argyle", payload);

      const { status, report, msg , Id} = response.data || {};
      if (status === 401) {
        setLoaderType(msg);
      }
      if (status === 200) {
        navigate("/argyle-report", {
          state: {
            id: Id,
            report: report,
            action: "Create",
            fromURL: "rarity",
          },
        });
      }
    } catch (e) {
      console.log("handleSubmit error", e);
      if(e.response?.status === 403){
      setLoaderType(e.response?.data?.msg);
        setLoading(true);
      }else{
        setLoading(false);
      }
    }
  };


  useEffect(() => {
    if (loader) {
      const interval = setInterval(() => {
        window.parent.postMessage("iframeIsReadyToReceiveUserData", "*");
      }, 1000);

      const handleMessage = (event) => {
          if (event.origin === process.env.REACT_APP_WP_BASE_URL || event.origin === "https://dev.fcresearch.org") {
          const {
            is_free,
            token,
            is_wp,

          } = event?.data;

          setIsWp(is_wp);
          if (token) {
            localStorage.setItem("is_free", is_free ? is_free : false);
            localStorage.setItem("fcrf@token", token);
            setLoader(false);
          } else {
            localStorage.removeItem("fcrf@token");
          }
        } else {
          if (!localStorage.getItem("fcrf@tokenr")) {
            if (window.location !== window.parent.location) {
              // The page is in an iframe
            } else {
              // The page is not in an iframe
              window.location.href = process.env.REACT_APP_WP_APP_LOGIN;
            }
          }
        }

        if (event?.data) {
          clearInterval(interval);
        }
      };

      window.addEventListener("message", handleMessage, false);

      return () => {
        window.removeEventListener("message", handleMessage);
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, loader]);

  // Scroll to View
  const initialWidth = window.innerWidth;
  const [, setScreenWidth] = useState(initialWidth);

  const handleResize = (e) => {
    const currentScreenSize = e.target.innerWidth;
    setScreenWidth(currentScreenSize);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  window.onpopstate = (event) => {
    if (
      location.search.includes("Step")
    ) {
      const stepStr = `?Step=1`;
      window.history.pushState(1, null, stepStr);
      setContext.setSetps(1);
      setPayload(initialState);
      setBackScroll(true);
      setTimeout(() => {
        setBackScroll(false);
      }, 250);
    }
  };
  const handleClose = () => {
    setLoading(false);
    setIsActive(false);
  };
  return (
    <>
    <Helmet>
      <title>FCRF - Argyle Calculator</title>
    </Helmet>
    {isActive === "teste" && <PageLoader />}
    {loading && <Loader type={loaderType} handleClose={handleClose} />}
    <div className="rarity-access-page rarity-argyle-page">
      <div className="giaBack argyle_Back">
        <LeftArrowSVg />
        <p
          onClick={() => {
            setShowArgyleContent(!showArgyleContent);
          }}
        >
          Back
        </p>
      </div>
      <div className="container argyle-section-for-pad">
        <div
          className={`rarity-access-content ${
            steps > 1 && steps <= 5 ? "rarity-scroll-content" : ""
          }`}
          id="rarityAccessPage"
        >
          {console.log("stepssteps", steps)}
          <h2>Select the parameters for your report</h2>
          {steps >= 1 && <DiamondWeight {...props} />}
          {steps >= 2 && <DiamondColor {...props} />}
          {steps >= 3 && <DiamondShape {...props} />}
          {steps >= 4 && <DiamondSaturation {...props} />}
          {steps >= 5 && <DiamondClarity {...props} />}
        </div>
      </div>

      <div className="rarity-process-wrapper" id="processBtn">
        <div className="container">
          <div className="rarity-process-content">
           {steps>5 && <Button
              className="processBtn"
              disabled={steps > 5 ? false : true}
              onClick={handleSubmit}
            >
              {loader  ? <Spinner animation="border" /> : "Process"}
            </Button>}
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default RarityArgyle;

const LeftArrowSVg = () => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5 12H3.5"
        stroke="#D5B65A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 19L3 12L10 5"
        stroke="#D5B65A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
