import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import FcrfContext from "../../Context";
const SettingSidebar = () => {
  const location = useLocation();
 

  const {
    
    custom_branding
  } = useContext(FcrfContext);
  return (
    <div className="setting-sidebar">
      <div className="setting-sidebar-wrapper">
        <div
          className={`setting-sidebar-item ${
            location.pathname === "/diary-setting" ? "active" : ""
          }`}
        >
          Diamond Diary
        </div>
        {
          custom_branding && custom_branding === "1"? <div className="setting-sidebar-item">Branding</div>:""
        }
       
      </div>
    </div>
  );
};

export default SettingSidebar;
