import { useContext } from "react";
import Form from "react-bootstrap/Form";
import FcrfContext from "../../Context";
import { ArgyleDiamonds } from "../../utils/argyleDiamondsDetail";

const DiamondClarity = ({ payload, handleOnchange }) => {
  const getContext = useContext(FcrfContext);
  const { rarityColor } = getContext;
  const result = ArgyleDiamonds.filter(
    (elem) => rarityColor.color === elem.color.img
  );
  const DiamondClaritys = result[0]?.clarity;
  return (
    <div
      className="diamond-clarity-wrapper diamond-color-wrapper diamond-weight-wrapper"
      id="diamondClarity"
    >
      <h5>Select the clarity of the diamond:</h5>
      <div className="diamond-shape-grid">
        {DiamondClaritys.map((el, i) => (
          <Form.Group className="diamond-btn-wrapper" key={i}>
            <input
              type="radio"
              id={`diamond${el?.value}`}
              name="clarity"
              value={payload?.clarity}
              onChange={(e) => handleOnchange(el?.value, e.target.name, 5)}
              checked={payload?.clarity === el?.value}
            />
            <div className="diamond-color-box">
              <div className="diamond-clarity-type">
                <h6>{el?.diamondType[0]}</h6>
                {el?.diamondType.length > 1 && <span>OR</span>}
                <h6>{el?.diamondType[1]}</h6>
              </div>
              <span>{el?.desc}</span>
            </div>
          </Form.Group>
        ))}
      </div>
    </div>
  );
};

export default DiamondClarity;
