import React from "react";

import SettingSidebar from "./settingSidebar";

const SettingLayout = ({ children }) => {
  return (
    <>
      <div className="setting-page-view">
        <SettingSidebar />
        <div className="content setting-content">{children}</div>
      </div>
    </>
  );
};

export default SettingLayout;
