export const rarityHandlePayload = (value, name, step, payload, from) => {
  let copyPayloadData = { ...payload };
  copyPayloadData[name] = value;
  if (step === 1) {
    copyPayloadData.carat = value;
    copyPayloadData.color = "";
    copyPayloadData.saturation = "";
    copyPayloadData.shape = "";
    copyPayloadData.clarity = "";
    copyPayloadData.select = "";
    copyPayloadData.giaNumber = "";
  } else if (step === 2) {
    copyPayloadData.saturation = "";
    copyPayloadData.shape = "";
    copyPayloadData.clarity = "";
    copyPayloadData.select = "";
  } else if (step === 3) {
    copyPayloadData.saturation = "";
    copyPayloadData.clarity = "";
    copyPayloadData.select = "";
  } else if (step === 4) {
    copyPayloadData.clarity = "";
    copyPayloadData.select = "";
  }
  return copyPayloadData;
};
