import React from 'react'

const NoReports = ({page="Diary"}) => {
  return (
    <>
     <h5 className="text-center"> No {page} Reports Found</h5>
    </>
  )
}

export default NoReports