import Chart from "react-apexcharts";

const ArgylePdfGemological = ({ report, GraphTextColor, barColor }) => {
  const { data, range_slider } = report || {};
  const { carat, clarity, color, saturation, shape, weightct } = data || {};
  const {
    clarity: clarityval,
    color: colorval,
    saturation: saturationval,
    shape: shapeval,
    carat: caratval,
  } = range_slider || {};
  const series = [
    {
      type: "column",
      //data: [74, 32, 2, 18, 25],
      data: [colorval, caratval, saturationval, shapeval, clarityval],
    },
  ];
  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: "70%",
        barHeight: "70%",
        colors: {
          backgroundBarColors: ["#e5e7f0"],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 4,
        },
        dataLabels: {
          position: "top",
        },
      },
    },
    fill: {
      colors: [barColor],
    },
    dataLabels: {
      formatter: function (labelValue) {
        return labelValue + "%";
      },
      style: {
        colors: [GraphTextColor],
        fontSize: "22px",
        fontWeight: "bold",
      },
      offsetX: 0,
      offsetY: -30,
    },
    stroke: {
      width: [0, 0, 0],
    },
    xaxis: {
      categories: [color, weightct, saturation, shape, clarity],
      labels: {
        show: false,
        style: {
          fontSize: "20px",
        },
      },
    },
    grid: {
      show: false,
    },
    markers: {
      size: 6,
      strokeWidth: 3,
      fillOpacity: 0,
      strokeOpacity: 0,
      hover: {
        size: 8,
      },
    },
    yaxis: {
      tickAmount: 4,
      min: 0,
      max: 100,
      labels: {
        formatter: (value) => {
          return value;
        },
        style: {
          fontSize: "20px",
        },
      },
    },
    tooltip: {
      enabled: false,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: "14px",
        fontFamily: undefined,
        colors: ["#1B2653"],
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },

      marker: {
        show: true,
      },
      items: {
        display: "block",
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 50,
      },
    },
  };
  return (
    <div className="rarity-tool-card pdf-argyle-gemo-toolcard pdf-gemo-toolcard">
      <div className="rarity-card-content">
        <h6>Gemological Characteristics</h6>
        <p>Market Distribution Percentage</p>
      </div>
      <div className="rarity-gemological-wrapper">
        <Chart
          options={options}
          type="bar"
          series={series}
          width="100%"
        />
        <div className="chart-xaxis-wrapper pdf-chart-xaxis">
          <span>{color}</span>
          <span>{carat} ct</span>
          <span>{saturation}</span>
          <span>{shape}</span>
          <span>{clarity}</span>
        </div>
      </div>
    </div>
  );
};

export default ArgylePdfGemological;
