import React, { useRef, useEffect } from "react";
import { getColorCode } from "../../utils/graphColor";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

export const ChartData = ({ color, value, label }) => {
  const chartDataSpring = useSpring({
    from: { width: "0%" },
    to: { width: `${value || 0}%` },
    config: { duration: 2000 },
  });

  return (
    <animated.div className="custom-chart-section-wrapper">
      <span className="custom-chart-label">{label || ""}</span>
      <div className="custom-chart-main">
        <div className="custom-chart-ruler">
          <div className="custom-chart-ruler-point">0%</div>
          <div className="custom-chart-ruler-point custom-chart-point-25"></div>
          <div className="custom-chart-ruler-point">50%</div>
          <div className="custom-chart-ruler-point custom-chart-point-75"></div>
          <div className="custom-chart-ruler-point">100%</div>
        </div>
        <div className="custom-chart-progress-wrapper">
          <animated.div
            className="custom-chart-progress"
            style={{ background: color, width: chartDataSpring.width }}
          ></animated.div>
        </div>
      </div>
    </animated.div>
  );
};

const GemologicalCharacteristics = ({
  graph_texts,
  labelName,
  labelValue,
  loading,
  section
}) => {
  const color = getColorCode(labelName[0]);
  const gemologicalRef = useRef();
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      // Trigger animation when in view
      gemologicalRef.current.style.opacity = 1;
      gemologicalRef.current.style.transform = "translateY(0)";
    }
  }, [inView]);

  return (
    <animated.div
      className="rarity-tool-card rarity-gemological-card"
      ref={(node) => {
        gemologicalRef.current = node;
        inViewRef(node);
      }}
      style={{
        opacity: 0,
        transform: "translateY(20px)",
      }}
    >
      <div className="rarity-card-content rarity-gemological-card-mb">
        {section==="rarity"?" " :<h6>Gemological Characteristics</h6>}
        <p>Market Distribution Percentage</p>
      </div>

      <div className="rarity-gemological-wrapper">
        {inView&&labelValue.map((value, i) => (
          <ChartData
            label={graph_texts[i]}
            color={color}
            value={value}
            key={i}
          />
        ))}
      </div>
    </animated.div>
  );
};

export default GemologicalCharacteristics;
