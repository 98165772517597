import { withAuth } from "../../config/axios";

const fetchUserSettings = async () => {
  try {


    const response = await withAuth().get(`users/setting`);
   
    if (response?.status === 200) {
      const diarySettingsObj = response?.data?.userSettings?.diarySettings;
      const companyLogoURL = response?.data?.userSettings?.companylogo;
      const custom_branding = response?.data.custom_branding;
      return { diarySettingsObj, companyLogoURL, custom_branding };
    }
  } catch (error) {
    console.log("Error while fetching user settings---", error);
  }
};

export default fetchUserSettings;
