import axios from "axios";
import { serverPath } from "./key";
export const withoutAuth = () => {
  return axios.create({
    baseURL: `${serverPath}`,
    headers: {
      userLocation: localStorage.getItem("curr_location"),
    },
  });
};

export const withAuth = () => {
  return axios.create({
    baseURL: `${serverPath}`,
    headers: {
      userLocation: localStorage.getItem("curr_location"),
      Authorization: `Bearer ${localStorage.getItem("fcrf@token")}`
    },
    timeout: 1000 * 10000,
  });
};

export const rarityInstance = () => {
  // start=>
  const product_id = localStorage.getItem("product_id") || "";
  const product_name = localStorage.getItem("item_name") || "";
  const member_type = localStorage.getItem("is_role") || "";
  const { is_a_child_user, parent_user_email } =
    JSON.parse(localStorage.getItem("is_child_access")) || {};
  const childUser = is_a_child_user === true ? parent_user_email : "FALSE";
  const userExtraDetails = {
    product_id,
    product_name,
    member_type,
    childUser,
  };
  return axios.create({
    baseURL: serverPath,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("fcrf@token")}`,
      userExtraDetails: JSON.stringify(userExtraDetails),
      userLocation: localStorage.getItem("curr_location"),
    },
    timeout: 1000 * 10000,
  });
};
