import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../footer/footer";
import Sidebar from "./sidebar";
import leftArrow from "../../assets/images/rarity/icon-arrow-left.svg";
import "./layout.css";
import FcrfContext from "../../Context";

const DiaryLayout = ({
  children,
  sideBar,
  header,
  handleSideBarCard,
  sidebarCard,
  from,
  handleCheckLogin,
  className,
  storyLoading
}) => {
  const { setPrevLocation, navigateFromPage, } =
    useContext(FcrfContext);
  const navigate = useNavigate();
  const location = useLocation();
  const screenWidth = window?.innerWidth;
  const [openSidebar, setOpenSidebar] = useState(
    screenWidth < 1024 ? false : true
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);


 

  const handleBack = () => {
    if (location.pathname === "/diary-report") {
      navigate("/diary", undefined);
      setPrevLocation("");
    } else {
      if (
        location.pathname === "/diary-history-report" ||
        location.pathname === "/diary-save-history"
      ) {
        setPrevLocation("/diary-history-report");
       
        if (navigateFromPage === "/diary") {
          setPrevLocation("");
        }
      
          navigate(-1);
  
      }
      if (navigateFromPage === "/diary-report") {
        navigate(navigateFromPage, {
          state: {
            Id: location?.state?.Id,
          },
        });
      } else {
        navigate(navigateFromPage);
      }
    }
  };

  return (
    <div
      className={`diary-main-page ${
        location.pathname === "/diary"
          ? "diary-home-page"
          : location.pathname === "/diary-report"
          ? "diary-report-page"
          : ""
      }`}
    >
      {header ? (
        <div className={`${className?className:""} rarity-header diary-header`}>
          <div className={`container ${openSidebar ? "open" : ""}`}>
            <div
              className={`diary-top-header ${
                location.pathname === "/diary" ? "dairy-input-page" : ""
              } ${(location.pathname === "/diary-save-history" || location.pathname === "/diary-history-report") ? "dairy-save-report" : ""}`}
            >
              {location.pathname !== "/diary" ? (
                <div
                  className='back-btn'
                  onClick={() => handleBack()}
                  role='presentation'
                >
                  <img src={leftArrow} alt='' /> BACK
                </div>
              ) : (
                ""
              )}
              
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className='diary-content'>
        {sideBar ? (
          <Sidebar
            handleSideBarCard={handleSideBarCard}
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            storyLoading={storyLoading}
          />
        ) : (
          ""
        )}
        <div className='content'>{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default DiaryLayout;
