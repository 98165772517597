import React from "react";

const PdfDiamondRare = ({ report }) => {
  const { texts } = report || {};
  const { text22 } = texts || {};

  const RareOffers = [
    {
      id: 1,
      name: "neverOffered",
      value: "Never Offered",
    },
    {
      id: 2,
      name: "offeredOnce",
      value: "Offered Once",
    },
    {
      id: 3,
      name: "twiceOffered",
      value: "Offered Twice",
      checked: true,
    },
    {
      id: 4,
      name: "offeredThreeTimes",
      value: "Offered Three Times",
    },
    {
      id: 5,
      name: "offeredFourTimes",
      value: "Offered Four Times",
    },
  ];
  return (
    <div className="rarity-tool-card rare-diamond-card">
      <div className="rarity-card-content">
        <h6>How rare is your diamond?</h6>
        <div className="diamond-characteristics-desc">
          <p>{texts?.text}</p>
        </div>
      </div>
      <div className="diamond-offered-wrapper">
        {RareOffers.map((e) => (
          <div className={`offered-tile ${e?.name}`} key={e?.id}>
            <label htmlFor={e?.name}>{e?.value}</label>

            <input
              type="radio"
              name="pdfOffer"
              id={e?.name}
              checked={
                e?.value?.toLowerCase() === text22?.toLowerCase() ? true : false
              }
              readOnly
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PdfDiamondRare;
