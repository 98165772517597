/* eslint-disable eqeqeq */
import { useEffect, useState, useContext } from "react";
import { rarityInstance, withAuth } from "../../config/axios";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/loader";
import "../savedReport/savedReport.css";
import { getlocalStorage } from "../../utils/getlocalStorage";
import DiaryLayout from "../layout/diaryLayout";
import { Spinner } from "react-bootstrap";
import {
  DiaryCauseShape,
  DiaryDiamondColors,
  DiaryDiamondShapes,
} from "../../utils/dairyDiamondsDetail";
import DiaryRenameDelete from "../Models/diaryRenameDelete";
import SaveNotificationModal from "../Models/saveNotificationModal";
import FcrfContext from "../../Context";
import InfiniteScroll from "react-infinite-scroll-component";
const DiarySaveHistoryPage = () => {
  const getContext = useContext(FcrfContext);
  const { savedNotification,setSidebarCard } = getContext;
  const navigate = useNavigate();
  const initialState = { reportId: "", type: "", recordName: "" };
  const [popupData, setpopupData] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [selectItem, setSelectItem] = useState(false);
  const [reportsData, setReportsData] = useState([]);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [show, setShow] = useState(false);
  const [dataMsg, setDataMsg] = useState("");
  const[hasMore,sethasMore]=useState(true)
  const[page,setPage]=useState(1)
  const[totalReportCount,setTotalReportCount]=useState(0)
 const limit =20
  const getTime = (time) => {
    let d = new Date(time);
    let pm = d.getHours() >= 12;
    let hour12 = d.getHours() % 12;
    if (!hour12) hour12 += 12;
    let minute = d.getMinutes();
    let date = d.getDate();
    let month = d.toLocaleString("en-US", { month: "short" });
    let year = d.getFullYear();
    return `${month}' ${dateSuffix(date)} ${year} ${hour12}:${
      minute < 10 ? "0" + minute : minute
    } ${pm ? "PM" : "AM"}`;
  };

  function dateSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  const handleSelect = (id) => {
    setSelectItem(true);
    setSelectedPostId(id);
  };

  const getCalculation = async (id, reportId) => {
    let getReportDataById = reportsData.find((el) => el.postId === id);
    const {origanalShowCardId} = getReportDataById?.report; 
    //showCardId sidebarCard
    setSidebarCard(origanalShowCardId);
    const payload = {
      report_number: getReportDataById?.report?.data.report_number,
      mine: getReportDataById?.report?.data.mine,
    };
    const copyfilterdata = { ...getReportDataById };
    const { is_free, is_subscription, is_role, service } = getlocalStorage();
    copyfilterdata.free = is_free;
    copyfilterdata.subscription = is_subscription || "not active";
    copyfilterdata.role = is_role;
    copyfilterdata.type = "Diary Search save";
    copyfilterdata.service = service;
    await rarityInstance().post("diamond/searchWebhook", copyfilterdata);
    navigate("/diary-report", {
      state: {
        filter: payload,
        action: "Get",
        from: "diarySearchHistory",
        Id: reportId,
      },
    });
  };



  //open the modal
  const handleOpenPopup = (data) => {
    setDataMsg(data.type);
    setpopupData(data);
    setShow(!show);
  };

  const handleClosePopupAfterSuccess = (reportId,msg,data=null) => {
    setShow(false);
    setpopupData(initialState);

    if(msg==="Record Deleted successfully!"){
      const updatedReportsData= reportsData.filter(item => 
        item._id !==reportId
        
        );

      setReportsData(updatedReportsData);
    }

    if(msg==="Record Updated Successfully!" && data){
      const updatedReportsData = reportsData.map(item => {
        if (item._id === data._id) {
          console.log("hello ",item._id)
          return data;
        }
        return item;
      });
      setReportsData(updatedReportsData);
    }
  };

  const handleClosePopup = () => {
    setpopupData(initialState);
    setShow(!show);
  };

  const saveHistoryReport = async (page,limit) => {
    try {
      const response = await withAuth().get("diamond/saveHistory" ,{params:{
        page: page,
        limit: limit
      }});
      //console.log("response ===>", response);
      const { status, getReport } = response?.data || {};
      if (status === 200) {
        const {data, totalResult } = getReport || {}
        setReportsData([...reportsData,...data]);
        setTotalReportCount(totalResult)
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    let effectvar=true
    if(effectvar){
      saveHistoryReport(page,limit);
    }
    return ()=>effectvar=false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const fetchData=()=>{
    console.log("hello");
    console.log("totalReportCount",totalReportCount);
    console.log("reportsData?.length",reportsData?.length);
    if(reportsData?.length>=totalReportCount){
      sethasMore(false)
    }
    else{
      setTimeout(() => {
        setPage(page+1)
      }, 1000);
      
    }
    
  }

  return (
    <DiaryLayout header={true}>
      <div className="saved-report-page">
        {loading && <Loader type="progress" />}

        <div className="container">
          <div className="saved-report-wrapper">
            <div className="saved-report-title">
              <h1>Saved Reports</h1>
            </div>

            <InfiniteScroll
                          dataLength={reportsData?.length}
                          next={fetchData}
                          hasMore={hasMore}
                          loader={<div className="mt-5"><Spinner animation="border" variant="warning" /></div>}
                          style={{ overflow:"hidden",textAlign: 'center',display:"flex",flexDirection:"column",gap:'32px' }}
                          
                        >
            {Array.isArray(reportsData) && reportsData?.length > 0
              ? reportsData
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  ?.map((el) => {
                    const { report } = el;
                    return (
                      <>
                        {el?.isShow !== false && (
                          <SavedCard
                            weight={report?.data?.weight}
                            color={report?.data?.color}
                            colorName={report?.data?.colorName}
                            shape={report?.data?.shape_code}
                            saturation={report?.data?.saturation}
                            postId={el?.postId}
                            reportId={el?._id}
                            recordName={el?.recordName}
                            selectedPostId={selectedPostId}
                            gia_number={report?.data?.report_number}
                            time={
                              report?.timestamp &&
                              getTime(new Date(report?.timestamp).toString())
                            }
                            selectItem={selectItem}
                            handleSelect={handleSelect}
                            getCalculation={getCalculation}
                            handleOpenPopup={handleOpenPopup}
                            gia_report={report?.gia_report}
                          />
                        )}
                      </>
                    );
                  })
              : "No data found"}
              <div className="divider"></div>
              </InfiniteScroll>
            
          </div>
        </div>
      </div>

      <DiaryRenameDelete
        show={show}
        popupData={popupData}
        handleClosePopup={handleClosePopup}
        handleClosePopupAfterSuccess={handleClosePopupAfterSuccess}
        from="save"
        reportType="diamond"
      />
      
      {savedNotification && (
        <SaveNotificationModal
          message={
            dataMsg === "delete"
              ? "Report deleted successfully"
              : "Report name has been updated"
          }
          undoBtn={false}
        />
      )}
    </DiaryLayout>
  );
};

export default DiarySaveHistoryPage;

const SavedCard = ({
  weight,
  color,
  recordName,
  shape,
  saturation,
  postId,
  reportId,
  selectedPostId,
  gia_number,
  time,
  handleSelect,
  getCalculation,
  handleOpenPopup,
  gia_report,
  colorName
}) => {
  const [imgScaleVal, setImgScaleVal] = useState(1);
  let rarityColor = DiaryDiamondColors.filter((el) => el.value === color);
  const resultShapeCode = DiaryCauseShape.filter((elem) => shape === elem.code);
  const showShapeImgCode = resultShapeCode[0]?.shape;
  const resultShape = DiaryDiamondShapes.filter(
    (elem) => showShapeImgCode === elem.value
  );
  var showShapeImg = resultShape[0]?.img;
  if(gia_report?.results.data?.shape?.shape_code==="OMB"){
    if(gia_report?.results.data?.shape?.shape_group==="Cushion"){
      showShapeImg="cushion"
   }
   else{
    showShapeImg = resultShape[0]?.img;
   }
   }

  const sizeValueInit =
    gia_report?.results?.data?.measurements?.length /
      gia_report?.results?.data?.measurements?.width || 0;

  const sizeValue =
    sizeValueInit.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] || 0;
    const names = `${saturation}
    ${"  "}
    ${colorName}
    ${"  "}
    ${weight}ct`;
  useEffect(() => {
    if (
      showShapeImgCode === "Cushion" ||
      showShapeImgCode === "Radiant"
    ) {
      if (sizeValue <= 1.05) {
        setImgScaleVal(1);
      } else if (sizeValue >= 1.06 && sizeValue <= 1.14) {
        setImgScaleVal(1.075);
      } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
        setImgScaleVal(1.15);
      } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
        setImgScaleVal(1.225);
      } else if (sizeValue >= 1.35) {
        setImgScaleVal(1.3);
      }
    } else if (showShapeImgCode === "Emerald") {
      if (sizeValue <= 1.14) {
        setImgScaleVal(0.775);
      } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
        setImgScaleVal(0.85);
      } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
        setImgScaleVal(0.925);
      } else if (sizeValue >= 1.35 && sizeValue <= 1.44) {
        setImgScaleVal(1);
      } else if (sizeValue >= 1.45) {
        setImgScaleVal(1.075);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeValue]);

  return (
    <>
      <div
        id={`${postId}`}
        className={`saved-card-wrapper ${
          postId === selectedPostId ? "active" : ""
        } `}
        key={postId}
      >
        <div className="diary-saved-card-img">
          <div className="icons-wrapper">
            <div
              className="diary-delete-icon"
              onClick={() =>
                handleOpenPopup({ reportId, type: "delete", recordName: recordName ||"" })
              }
              role="presentation"
            >
              <DeleteIcon />
            </div>
          </div>
          <div className="saved-card-right" >
            <div className="saved-card-content">
            
              <div className="saved-card-title-wrapper text-start">
                <span
                  onClick={() => getCalculation(postId, reportId)}
                  role="presentation"
                >
                  {recordName ? recordName : names}
                  {"  "}
                  {gia_number ? `(${gia_number})`:"(Manualy)"}
                </span>
                <div
                  className="diary-delete-icon diary-edit-icon"
                  onClick={() =>
                    handleOpenPopup({
                      reportId,
                      type: "rename",
                      recordName: recordName ?? names,
                    })
                  }
                  role="presentation"
                >
                  <EditIcon />
                </div>
              </div>
              
              <div
                className="saved-card-date"
                onClick={() => getCalculation(postId, reportId)}
                role="presentation"
              >
                Results reflect the data as of{" "}
                <span className="diamond-date-td">{time}</span>
              </div>
            </div>
          </div>
        </div>
        
        <div
          className="saved-card-img flex-grow-1 d-flex justify-content-end"
          onClick={() => getCalculation(postId, reportId)}
          role="presentation"
        >
          <img
            className={`diamond-saturation w-auto ${saturation}`}
            src={`images/diamond-${rarityColor[0]?.img}-${showShapeImg}.svg`}
            alt={rarityColor[0]?.img}
            style={{ transform: `scaleY(${imgScaleVal})` }}
          />
        </div>
      </div>
     
    </>
  );
};

const DeleteIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66666 5.33334L4.34165 18.8331C4.43035 20.6072 5.89457 22 7.67082 22H16.3292C18.1054 22 19.5696 20.6072 19.6583 18.8331L20.3333 5.33334H3.66666ZM5.41874 7.00001L6.00623 18.7499C6.05058 19.6369 6.7827 20.3333 7.67082 20.3333H16.3292C17.2173 20.3333 17.9494 19.6369 17.9937 18.7499L18.5812 7.00001H5.41874Z"
        fill="#E5E7EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 5.33333H9.50001V4.5C9.50001 4.03976 9.87311 3.66667 10.3333 3.66667H13.6667C14.1269 3.66667 14.5 4.03976 14.5 4.5V5.33333ZM7.83334 7V4.5C7.83334 3.11929 8.95263 2 10.3333 2H13.6667C15.0474 2 16.1667 3.11929 16.1667 4.5V7H7.83334Z"
        fill="#E5E7EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6.16668C2 5.70644 2.3731 5.33334 2.83333 5.33334H21.1667C21.6269 5.33334 22 5.70644 22 6.16668C22 6.62691 21.6269 7.00001 21.1667 7.00001H2.83333C2.3731 7.00001 2 6.62691 2 6.16668Z"
        fill="#E5E7EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 10.3333C14.9602 10.3333 15.3333 10.7064 15.3333 11.1667V15.3333C15.3333 15.7936 14.9602 16.1667 14.5 16.1667C14.0398 16.1667 13.6667 15.7936 13.6667 15.3333V11.1667C13.6667 10.7064 14.0398 10.3333 14.5 10.3333Z"
        fill="#E5E7EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.49999 10.3333C9.96023 10.3333 10.3333 10.7064 10.3333 11.1667V15.3333C10.3333 15.7936 9.96023 16.1667 9.49999 16.1667C9.03975 16.1667 8.66666 15.7936 8.66666 15.3333V11.1667C8.66666 10.7064 9.03975 10.3333 9.49999 10.3333Z"
        fill="#E5E7EF"
      />
    </svg>
  );
};

const EditIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.556 2.55558C16.556 2.55558 18.6936 1.58204 20.556 3.44447C22.4184 5.3069 21.4449 7.44446 21.4449 7.44446L9.00049 19.8889L3.44495 20.5555L4.11161 15L16.556 2.55558ZM5.45051 15.6507L5.05516 18.9453L8.34973 18.55L20.2184 6.68128C20.2541 6.54126 20.289 6.34748 20.2921 6.12347C20.2984 5.66802 20.1792 5.0573 19.5612 4.43929C18.9432 3.82129 18.3325 3.70209 17.877 3.70838C17.653 3.71147 17.4592 3.74639 17.3192 3.78205L5.45051 15.6507Z"
        fill="#E5E7EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1878 10.2356L13.5319 5.5797L14.8583 4.25333L19.5141 8.90919L18.1878 10.2356Z"
        fill="#E5E7EF"
      />
    </svg>
  );
};
