import { useContext, useEffect, useState } from "react";
import {
  DiaryCauseShape,
  DiaryDiamondShapes,
  DiaryDiamondColors,
} from "../../utils/dairyDiamondsDetail";
import FcrfContext from "../../Context";
import { useMemo } from "react";
import { useRef } from "react";

const FaceUpDiary = ({
  profilerInfo,
  data,
  gia_report,
  imgScaleVal,
  range_slider,
  sidebarCard,
  handleSideBarCard,
  isHide,
}) => {
  const { setPdfReportData } = useContext(FcrfContext);
  const [viewOnce, setViewOnce] = useState(false);

  const faceUpBoxAnimate = useRef(null);
  const isInViewport = useIsInViewport(faceUpBoxAnimate);
  const { results } = gia_report || {};
  setTimeout(() => {
    if (isInViewport) {
      setViewOnce(true);
    }
  }, 500);

  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }

  const result = DiaryDiamondColors.filter(
    (elem) =>
      data?.color === elem.value
  );
  const showColorImg = result[0]?.img;

 

  const resultShapeCode = DiaryCauseShape.filter(
    (elem) => data?.shape_code === elem.code
  );
  const showShapeImgCode = resultShapeCode[0]?.shape;
  const resultShape = DiaryDiamondShapes.filter(
    (elem) => showShapeImgCode === elem.value
  );
  var showShapeImg = resultShape[0]?.img;

  if(results?.data?.shape?.shape_code==="OMB"){
    if(results?.data?.shape?.shape_group==="Cushion"){
      showShapeImg="cushion"
   }
   else{
    showShapeImg = resultShape[0]?.img;
   }
   }


  const surfaceText1 = profilerInfo?.C56?.split("#") || [];
  const surfaceText3 = profilerInfo?.C60?.split("#") || [];
  const surfaceText4 = profilerInfo?.C58?.split("#") || [];
  const ImageScaleValue = profilerInfo?.F60;

  useEffect(() => {
    const surfaceAreaBox = {
      heading: profilerInfo?.B54,
      surfaceText1: surfaceText1?.length > 0 ? surfaceText1 : null,
      surfaceText3: surfaceText3?.length > 0 ? surfaceText3 : null,
      surfaceText4: surfaceText4?.length > 0 ? surfaceText4 : null,
      measurements:
        range_slider?.shapeName !== "Round"
          ? `${gia_report?.results?.data?.measurements?.length}mm
     x ${gia_report?.results?.data?.measurements?.width}mm`
          : `${(
              (gia_report?.results?.data?.measurements?.min_diam +
                gia_report?.results?.data?.measurements?.max_diam) /
              2
            ).toFixed(2)}mm`,
      faceUpFirstImageScale: `${
        ImageScaleValue === "1" ||
        ImageScaleValue === "2" ||
        ImageScaleValue === "3" ||
        ImageScaleValue === "0"
          ? "210px"
          : ImageScaleValue === "-1"
          ? "200px"
          : ImageScaleValue === "-2"
          ? "190px"
          : ImageScaleValue === "-3"
          ? "180px"
          : ""
      }`,
      faceUpSecondImageScale: `${
        ImageScaleValue === "0" ||
        ImageScaleValue === "-1" ||
        ImageScaleValue === "-2" ||
        ImageScaleValue === "-3"
          ? "210px"
          : ImageScaleValue === "1"
          ? "200px"
          : ImageScaleValue === "2"
          ? "190px"
          : ImageScaleValue === "3"
          ? "180px"
          : ""
      }`,
    };
    setPdfReportData((prev) => ({ ...prev, surfaceAreaBox }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showColorImg]);
  return (
    <section
      className={`diary-card ${sidebarCard?.surfaceAreaBox ? "show" : "hide"}`}
      id="surfaceAreaBox"
    >
      {!isHide && (
        <div className="mobile-hide-card-btn">
          <label className="diary-check-container">
            <input
              type="checkbox"
              id={`nav-surfaceAreaBox`}
              name={`surfaceAreaBox`}
              onChange={() =>
                handleSideBarCard("surfaceAreaBox", sidebarCard?.surfaceAreaBox)
              }
              checked={sidebarCard?.surfaceAreaBox}
            />
            <div className="checkmark"></div>
          </label>
        </div>
      )}
      <h4 className="title-long-width">{profilerInfo?.B54}</h4>
      
      <div className="diary-card-content faceup-card-main-content">
        <div className="faceup-top">
          
          <p>
            {surfaceText1[0]}
            <b>{surfaceText1[1]}</b>
            {surfaceText1[2]}
          </p>
          <p>
            {surfaceText4[0]}
            <b>{surfaceText4[1]}</b>
            {surfaceText4[2]}
          </p>
        </div>
        <div className="faceup-bottom faceup-card-content">
          <div className="faceup-text-wrapper">
            <p>
              {surfaceText3[0]}
              <b>{surfaceText3[1]}</b>
              {surfaceText3[2]}
              <b>{surfaceText3[3]}</b>
              {surfaceText3[4]?.replace("<br>","")}
              
            </p>
            <p>
            <b>{surfaceText3[5]}</b>
              {surfaceText3[6]}
              <b>{surfaceText3[7]}</b>
              {surfaceText3[8]}
            </p>
          </div>
          <div className="faceup-frame-content">
            <div className="faceup-frame-wrapper" ref={faceUpBoxAnimate}>
              <span className="diamondTxt1">
                {range_slider?.shapeName !== "Round"
                  ? `Your diamond ${gia_report?.results?.data?.measurements?.length}mm
                x ${gia_report?.results?.data?.measurements?.width}mm`
                  : `Your average diameter is ${(
                      (gia_report?.results?.data?.measurements?.min_diam +
                        gia_report?.results?.data?.measurements?.max_diam) /
                      2
                    ).toFixed(2)}mm
                  `}
              </span>
              <span className="diamondTxt2">
                Average face-up for this weight
              </span>
              <div className={showShapeImg === "radient" ? "faceup-frame" : "faceup-frame"}>
                <div
                  className="faceup-frame-images"
                  style={{ transform: `translateY(${imgScaleVal > 1.24 ? "0" : "0"}) scaleY(${imgScaleVal})` }}
                >
                  <img
                    className={`face-card-image faceup-image-first ${
                      viewOnce ? "animate" : ""
                    }`}
                    src={`/images/diamond-${showColorImg}-${showShapeImg}.svg`}
                    alt={`${showColorImg}-${showShapeImg}`}
                    style={{
                      transform: `translate(${viewOnce ? "0%" : "98%"}, ${
                        range_slider?.shapeName === "Pear" ? "6%" : "0%"
                      })`,
                      width: `${
                        ImageScaleValue === "1" ||
                        ImageScaleValue === "2" ||
                        ImageScaleValue === "3" ||
                        ImageScaleValue === "0"
                          ? "200px"
                          : ImageScaleValue === "-1"
                          ? "190px"
                          : ImageScaleValue === "-2"
                          ? "180px"
                          : ImageScaleValue === "-3"
                          ? "170px"
                          : ""
                      }`,
                    }}
                  />
                  <img
                    className={`face-card-image ${viewOnce ? "animate" : ""}`}
                    src={`/images/diamond-${showColorImg}-${showShapeImg}.svg`}
                    alt={`${showColorImg}-${showShapeImg}`}
                    style={{
                      transform: `translateY(${
                        range_slider?.shapeName === "Pear" ? "6%" : "0"
                      })`,
                      width: `${
                        ImageScaleValue === "0" ||
                        ImageScaleValue === "-1" ||
                        ImageScaleValue === "-2" ||
                        ImageScaleValue === "-3"
                          ? "200px"
                          : ImageScaleValue === "1"
                          ? "190px"
                          : ImageScaleValue === "2"
                          ? "180px"
                          : ImageScaleValue === "3"
                          ? "170px"
                          : ""
                      }`,
                      opacity: `${viewOnce ? "1" : "0"},`,
                    }}
                  />
                
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaceUpDiary;