import React from "react";

const RarityToolCard = ({
  cardDesc,
  loading,
  line,
  isExportBtn,
  handleCreateNewReport,
  cardRef,
  options,
  showFullText,
  toggleReadMore,
  id,
  cardDesc2,
  readmore,
  handleReadMore,
  className,
  readMore,
}) => {
  const paraDetail =
    document?.getElementById("rarityText")?.getBoundingClientRect()?.height ||
    {};

  return (
    <div
      className={`rarity-tool-card rarity-tool-card-right ${
        className ? className : ""
      }`}
      id={id}
    >
      <div className="rarity-card-content">
        <p>
          {cardDesc
            ? readMore && !showFullText
              ? cardDesc + "..."
              : cardDesc
            : ""}
        </p>
        {readMore && (
          <p
            style={{ cursor: "pointer" }}
            className="text-decoration-underline"
            onClick={toggleReadMore}
          >
            {showFullText ? "" : "Read More..."}
          </p>
        )}

        {cardDesc2 && (
          <div
            className="dec-para-rarity"
            style={{ height: `${readmore ? `${paraDetail}px` : "0px"}` }}
          >
            <p id="rarityText">{cardDesc2}</p>
          </div>
        )}
        {cardDesc2 && (
          <span onClick={handleReadMore}>
            {readmore ? "Read Less" : "Read More..."}
          </span>
        )}
      </div>
    </div>
  );
};

export default RarityToolCard;
