import { useEffect, useContext } from "react";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import FcrfContext from "../../Context";

const LuxuryHistory = ({
  famousDiamondInfo,
  sidebarCard,
  handleSideBarCard,
  isHide
}) => {
  const {setPdfReportData} = useContext(FcrfContext);
  const titleTxt = famousDiamondInfo?.B48?.split("<br>") || [];
  const famousImgTxt = famousDiamondInfo?.C52?.split(",") || [];
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    className: "myCustomCarousel",
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
  };
  useEffect(() => {
    const luxuryHistoryBox = {
      titleTxt,
      titlePara: famousDiamondInfo?.C50,
      famousImgTxtArray: famousImgTxt
    };
    setPdfReportData((prev)=>({...prev, luxuryHistoryBox}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [famousDiamondInfo]);

  return (
    <section
      className={`diary-card ${
        sidebarCard?.luxuryHistoryBox ? "show" : "hide"
      }`}
      id="luxuryHistoryBox"
    >
          {!isHide &&
      <div className="mobile-hide-card-btn">
        <label className="diary-check-container">
          <input
            type="checkbox"
            id={`nav-luxuryHistoryBox`}
            name={`luxuryHistoryBox`}
            onChange={() =>
              handleSideBarCard(
                "luxuryHistoryBox",
                sidebarCard?.luxuryHistoryBox
              )
            }
            checked={sidebarCard?.luxuryHistoryBox}
          />
          <div className="checkmark"></div>
        </label>
      </div>
}
      <div className="diary-card-content history-card-content">
        <div className="famous-card-left">
          <h4 className="famous-card-title">
            {titleTxt[0]}
            <br />
            {titleTxt[1]}
          </h4>
          <p>
           
            {famousDiamondInfo?.C50}
          </p>
        </div>
        <div className="famous-card-right">
          <div className="famous-card-wrapper">
            <Slider {...settings}>
              {famousImgTxt.map((imgPath, i) => (
                <div className="diary-famous-img" key={i + 1}>
                  <img src={`/diaryImage/${imgPath}.png`} alt={imgPath} />
                </div>
              ))}
              
            </Slider>
          </div>
        </div>

       
      </div>
    </section>
  );
};

export default LuxuryHistory;