export const ArgyleDiamonds = [
  {
    color: { img: "pink", value: "Pink" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
      { id: 9, img: "hexagon", value: "Hexagon" },
      { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
      { id: 11, img: "octagon", value: "Octagon" },
      { id: 12, img: "princess", value: "Princess" },
      { id: 13, img: "trilliant", value: "Trilliant" },
      { id: 14, img: "kite", value: "Kite" },
      { id: 15, img: "shield", value: "Shield" },
    ],
    saturation: [
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "purplish-pink", value: "Purplish Pink" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
    ],
    saturation: [
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "purple-pink", value: "Purple Pink" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
      { id: 9, img: "hexagon", value: "Hexagon" },
      { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
      { id: 11, img: "octagon", value: "Octagon" },
      { id: 12, img: "princess", value: "Princess" },
      { id: 13, img: "trilliant", value: "Trilliant" },
      { id: 14, img: "kite", value: "Kite" },
      { id: 15, img: "shield", value: "Shield" },
    ],
    saturation: [
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "brwonish-orangy-pink", value: "Brownish Orangy Pink" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
      { id: 9, img: "hexagon", value: "Hexagon" },
      { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
      { id: 11, img: "octagon", value: "Octagon" },
      { id: 12, img: "princess", value: "Princess" },
      { id: 13, img: "trilliant", value: "Trilliant" },
      { id: 14, img: "kite", value: "Kite" },
      { id: 15, img: "shield", value: "Shield" },
    ],
    saturation: [
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "pink-purple", value: "Pink Purple" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
      { id: 9, img: "hexagon", value: "Hexagon" },
      { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
      { id: 11, img: "octagon", value: "Octagon" },
      { id: 12, img: "princess", value: "Princess" },
      { id: 13, img: "trilliant", value: "Trilliant" },
      { id: 14, img: "kite", value: "Kite" },
      { id: 15, img: "shield", value: "Shield" },
    ],
    saturation: [
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "red", value: "Red" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
      { id: 9, img: "hexagon", value: "Hexagon" },
      { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
      { id: 11, img: "octagon", value: "Octagon" },
      { id: 12, img: "princess", value: "Princess" },
      { id: 13, img: "trilliant", value: "Trilliant" },
      { id: 14, img: "kite", value: "Kite" },
      { id: 15, img: "shield", value: "Shield" },
    ],
    saturation: [
      "Fancy",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "purplish-red", value: "Purplish Red" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
    ],
    saturation: [
      "Fancy",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "brownish-red", value: "Brownish Red" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
      { id: 9, img: "hexagon", value: "Hexagon" },
      { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
      { id: 11, img: "octagon", value: "Octagon" },
      { id: 12, img: "princess", value: "Princess" },
      { id: 13, img: "trilliant", value: "Trilliant" },
      { id: 14, img: "kite", value: "Kite" },
      { id: 15, img: "shield", value: "Shield" },
    ],
    saturation: [
      "Fancy",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "blue-grey", value: "Blue Gray" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
      { id: 9, img: "hexagon", value: "Hexagon" },
      { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
      { id: 11, img: "octagon", value: "Octagon" },
      { id: 12, img: "princess", value: "Princess" },
      { id: 13, img: "trilliant", value: "Trilliant" },
      { id: 14, img: "kite", value: "Kite" },
      { id: 15, img: "shield", value: "Shield" },
    ],
    saturation: [
      
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      "Fancy Dark",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "grey-blue", value: "Gray Blue" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
      { id: 9, img: "hexagon", value: "Hexagon" },
      { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
      { id: 11, img: "octagon", value: "Octagon" },
      { id: 12, img: "princess", value: "Princess" },
      { id: 13, img: "trilliant", value: "Trilliant" },
      { id: 14, img: "kite", value: "Kite" },
      { id: 15, img: "shield", value: "Shield" },
    ],
    saturation: [
      
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      "Fancy Dark",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "grey-violet", value: "Gray Violet" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
      { id: 9, img: "hexagon", value: "Hexagon" },
      { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
      { id: 11, img: "octagon", value: "Octagon" },
      { id: 12, img: "princess", value: "Princess" },
      { id: 13, img: "trilliant", value: "Trilliant" },
      { id: 14, img: "kite", value: "Kite" },
      { id: 15, img: "shield", value: "Shield" },
    ],
    saturation: [
    
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      "Fancy Dark",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "grayish-bluish-violet", value: "Grayish Bluish Violet" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
      { id: 9, img: "hexagon", value: "Hexagon" },
      { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
      { id: 11, img: "octagon", value: "Octagon" },
      { id: 12, img: "princess", value: "Princess" },
      { id: 13, img: "trilliant", value: "Trilliant" },
      { id: 14, img: "kite", value: "Kite" },
      { id: 15, img: "shield", value: "Shield" },
    ],
    saturation: [
      
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      "Fancy Dark",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "grayish-violetish-blue", value: "Grayish Violetish Blue" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
      { id: 9, img: "hexagon", value: "Hexagon" },
      { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
      { id: 11, img: "octagon", value: "Octagon" },
      { id: 12, img: "princess", value: "Princess" },
      { id: 13, img: "trilliant", value: "Trilliant" },
      { id: 14, img: "kite", value: "Kite" },
      { id: 15, img: "shield", value: "Shield" },
    ],
    saturation: [
     
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      "Fancy Dark",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "violet-grey", value: "Violet Gray" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
      { id: 9, img: "hexagon", value: "Hexagon" },
      { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
      { id: 11, img: "octagon", value: "Octagon" },
      { id: 12, img: "princess", value: "Princess" },
      { id: 13, img: "trilliant", value: "Trilliant" },
      { id: 14, img: "kite", value: "Kite" },
      { id: 15, img: "shield", value: "Shield" },
    ],
    saturation: [
     
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      "Fancy Dark",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "violetish-grey", value: "Violetish Gray" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
      { id: 9, img: "hexagon", value: "Hexagon" },
      { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
      { id: 11, img: "octagon", value: "Octagon" },
      { id: 12, img: "princess", value: "Princess" },
      { id: 13, img: "trilliant", value: "Trilliant" },
      { id: 14, img: "kite", value: "Kite" },
      { id: 15, img: "shield", value: "Shield" },
    ],
    saturation: [
      
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      "Fancy Dark",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "greyish-violet", value: "Grayish Violet" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
      { id: 9, img: "hexagon", value: "Hexagon" },
      { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
      { id: 11, img: "octagon", value: "Octagon" },
      { id: 12, img: "princess", value: "Princess" },
      { id: 13, img: "trilliant", value: "Trilliant" },
      { id: 14, img: "kite", value: "Kite" },
      { id: 15, img: "shield", value: "Shield" },
    ],
    saturation: [
     
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      "Fancy Dark",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
  {
    color: { img: "orangy-pink", value: "Orangy Pink" },
    shape: [
      { id: 1, img: "radient", value: "Radiant" },
      { id: 2, img: "cushion", value: "Cushion" },
      { id: 3, img: "oval", value: "Oval" },
      { id: 4, img: "heart", value: "Heart" },
      { id: 5, img: "pear", value: "Pear" },
      { id: 6, img: "emerald", value: "Emerald" },
      { id: 7, img: "round", value: "Round" },
      { id: 8, img: "marquise", value: "Marquise" },
      { id: 9, img: "hexagon", value: "Hexagon" },
      { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
      { id: 11, img: "octagon", value: "Octagon" },
      { id: 12, img: "princess", value: "Princess" },
      { id: 13, img: "trilliant", value: "Trilliant" },
      { id: 14, img: "kite", value: "Kite" },
      { id: 15, img: "shield", value: "Shield" },
    ],
    saturation: [
    
      "Fancy",
      "Fancy Intense",
      "Fancy Vivid",
      "Fancy Deep",
      "Fancy Dark",
    ],
    clarity: [
      { diamondType: ["FL or IF"], value: "IF", desc: "Flawless/Internally…" },
      { diamondType: ["VVS"], value: "VVS", desc: "Very Very Slightly Incl…" },
      { diamondType: ["VS"], value: "VS", desc: "Very  Slightly Inclusion" },
      { diamondType: ["SI1"], value: "SI1", desc: "Slightly Inclusion" },
      { diamondType: ["SI2"], value: "SI2", desc: "Slightly Inclusion" },
      { diamondType: ["I1"], value: "I1", desc: "Inclusion or Pique" },
      { diamondType: ["I2"], value: "I2", desc: "Inclusion or Pique" },
      { diamondType: ["I3"], value: "I3", desc: "Inclusion or Pique" },
    ],
  },
 
];

export const ArgyleDiamondShapes = [
  { id: 1, img: "radient", value: "Radiant" },
  { id: 2, img: "cushion", value: "Cushion" },
  { id: 3, img: "oval", value: "Oval" },
  { id: 4, img: "heart", value: "Heart" },
  { id: 5, img: "pear", value: "Pear" },
  { id: 6, img: "emerald", value: "Emerald" },
  { id: 7, img: "round", value: "Round" },
  { id: 8, img: "marquise", value: "Marquise" },
  { id: 9, img: "hexagon", value: "Hexagon" },
  { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
  { id: 11, img: "octagon", value: "Octagon" },
  { id: 12, img: "princess", value: "Princess" },
  { id: 13, img: "trilliant", value: "Trilliant" },
  { id: 14, img: "kite", value: "Kite" },
  { id: 15, img: "shield", value: "Shield" },
];
