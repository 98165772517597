import { withoutAuth } from "../../config/axios";

const getlastUpdate = async() =>{
    try{
      const response = await withoutAuth().get(
        `report/lastUpdate`
      );
      const {
       data
      } = response || {}
      return {data}
     
    }catch(e){
      console.log("e =>", e)
    }
  
   }

   export default getlastUpdate