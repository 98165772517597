import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
const SettingModal = ({
    show,
    handleClosePopup,
    handleClosePopupAfterSuccess,
    }) => {
const [isActive, setisActive] = useState(false);

const handleDelete = async () => {

            setisActive(true);
            setTimeout(() => {
                handleClosePopupAfterSuccess()
                setisActive(false);
            }, 500);

           
            
};
  return (
    <Modal
        className='diary-notice-modal'
        show={show}
        onHide={handleClosePopup}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
         <>
             
                <DeleteComponent
                  isActive={isActive}
                  handleDelete={handleDelete}
                  handleClosePopup={handleClosePopup}
                />
             
              
            </>
         
        </Modal.Body>
      </Modal>
  )
}

export default SettingModal

const DeleteComponent = ({
    isActive,
    handleDelete,
    handleClosePopup,
  }) => {
    return (
      <>
        <div className='diary-delete-modal '>
         
            <p>Are you sure you want to delete?</p>
         
          <div className='delete-modal-btn'>
            <Button
              className='no-outline-btn'
              disabled={isActive}
              onClick={handleDelete}
            >
              {" "}
              {isActive ? <Spinner animation='border' /> : "Delete"}
            </Button>
            <Button className='outline-btn' onClick={handleClosePopup}>
              Cancel
            </Button>
          </div>
        </div>
      </>
    );
  };