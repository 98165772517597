export const resultFormating = (graph_texts,range_slider,characteristics) => {
  let colorVal = range_slider?.color;
  let clarityVal = range_slider?.clarity;
  let wightVal = range_slider?.weight_category?range_slider?.weight_category: parseFloat(graph_texts?.B12?.match(/-?\d+\.?\d*/)?.[0]);
  let shapeVal = range_slider?.shape;
  let saturationVal =  range_slider?.saturation;
  if (isNaN(shapeVal)) {
    shapeVal = 0;
  }
  if (isNaN(clarityVal)) {
    clarityVal = 0;
  }
  if (isNaN(wightVal)) {
    wightVal = 0;
  }
  if (isNaN(colorVal)) {
    colorVal = 0;
  }
  if (isNaN(saturationVal)) {
    saturationVal = 0;
  }
  const wightValname  = range_slider?.category && range_slider?.category !== ''
    ? range_slider?.category
    : characteristics?.weight;

  const labelname = [
    characteristics?.color,
    wightValname + " carat",
    characteristics?.saturation,
    characteristics?.shape,
    characteristics?.clarity,
  ];
  const labelvalue = [colorVal, wightVal, saturationVal, shapeVal, clarityVal];
  const graph_tooltip_text =[graph_texts?.B14,graph_texts?.B12,graph_texts?.B10, graph_texts?.B11, graph_texts?.B13]
  return {labelname,graph_tooltip_text, labelvalue }
};
  