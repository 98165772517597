/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withoutAuth } from "../../config/axios";
import { Helmet } from "react-helmet";
import { queryString } from "../../utils/queryString";
import { decryptString, extractShareIdByKey } from "../../utils/decryptText";
import Loader from "../loader/loader";

import GemologicalCharacteristics from "./gemologicalCharacteristics";
import DiamondRare from "./diamondrarity";
import "../rarityReport/rarityReport.css";
import "./argyleReport.css";
import { clientPath } from "../../config/key";
import { ArgyleDiamonds } from "../../utils/argyleDiamondsDetail";
import { useLocation, useParams } from "react-router-dom";
const ShareRarityReport = () => {
  const shareArgyleIds = useParams();
  const location=useLocation()
  const { id } = queryString();
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState({});
  const [, setTime] = useState(null);
  const [metaImgData, setMetaImgData] = useState({
    color:"",
    shape:""
  })

  const [qrCodeUrl, setQrCodeUrl] = useState("")
  const [, setCompanyLogo] = useState("");
  const [, setCustomBranding] = useState("0");
  const sharePageLoaded = async (postId,createdBy)=>{
    try {
    
      await withoutAuth().post("report/share-link-open/Argyle", {
        reportId: postId,
        createdBy,
        shareUrl: window.location.href
      });
    } catch (error) {
      console.error('error in open link===',error);
    }
  }
  useEffect(() => {
    const params = location.search;

    async function fetchData(Id) {

      const {postId,createdBy}=   await getReportById(Id);
      await sharePageLoaded(postId,createdBy)
     
     
    }
    if(params?.includes("?id")){
        const decData = decryptString(id);
        const shareIdvalue = extractShareIdByKey(decData, "Id")
        fetchData(shareIdvalue);
      }
      else{
        const shareId = shareArgyleIds.id 
        console.log(shareId);
        fetchData(shareId);
      }

    
  }, [shareArgyleIds.id]);

  const getReportById = async (id) => {
    try {
      const response = await withoutAuth().post("report/getreportbyId", {
        id
      });
      const { getReport, companyInfo,createdBy , Qrurl } = response?.data;
      setQrCodeUrl(Qrurl)
      setCompanyLogo(companyInfo?.companyLogo || "")
      setCustomBranding(companyInfo?.customBranding || "0")
      const times = getTime(new Date(getReport?.timestamp).toString());
      setTime(times);
      //console.log("getReport =>", getReport)
      //const getReportData = getReport?.find((el) => el?.postId === postId);
      setReportData(getReport?.report);

      const resultColorImg = ArgyleDiamonds.filter(
        (elem) => elem?.color?.value === getReport?.report?.data?.color
      );
      const diamondColorImg = resultColorImg[0]?.color?.img;
      setMetaImgData({color:diamondColorImg , shape:getReport?.report?.data?.shape})

      setLoading(false);
      return ({postId:getReport?.report?.postId,createdBy})
    } catch (error) {
      console.log("error", error);
    }
  };

  const getTime = (time) => {
    let d = new Date(time);
    let pm = d.getHours() >= 12;
    let hour12 = d.getHours() % 12;
    if (!hour12) hour12 += 12;
    let minute = d.getMinutes();
    let date = d.getDate();
    let month = d.toLocaleString("en-US", { month: "short" });
    let year = d.getFullYear();
    return `${month} ${dateSuffix(date)} ${year} ${hour12}:${
      minute < 10 ? "0" + minute : minute
    } ${pm ? "PM" : "AM"}`;
  };

  function dateSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
 
  console.log("reportData?.data?.color =>", reportData?.data?.color)

  let GraphTextColor = "";
  const color = () => {
    switch (reportData?.data?.color) {
      case "Yellow":
        return "#ffda1a";
      case "Greenish Yellow":
        GraphTextColor = "#333";
        return "#c1e51a";
      case "Orange Yellow":
        return "#ffa330";
      case "Pink":
        return "#f563c0";
      case "Orangy Pink":
        return "#dc7979";
      case "Purplish Pink":
        GraphTextColor = "#333";
        return "#cf2985";
      case "Pinkish Purple":
        return "#a07aa0";
      case "Brownish Pink":
        GraphTextColor = "#333";
        return "#de7394";
      case "Brown Pink":
        GraphTextColor = "#333";
        return "#bc5b78";
      case "Blue":
        return "#4380c5";
      case "Gray Blue":
        return "#97bbe0";
      case "Green Blue":
        GraphTextColor = "#333";
        return "#55887e";
      case "Grayish Blue":
        GraphTextColor = "#333";
        return "#8faac5";
      case "Orange":
        GraphTextColor = "#333";
        return "#ff7c31";
      case "Yellow Orange":
        GraphTextColor = "#333";
        return "#fa9922";
      case "Yellowish Orange":
        GraphTextColor = "#333";
        return "#ff8630";
      case "Brown Orange":
        return "#d67f4a";
      case "Green":
        return "#547055";
      case "Yellow Green":
        GraphTextColor = "#333";
        return "#57ee1a";
      case "Blueish Green":
        GraphTextColor = "#333";
        return "#326b72";
      case "Red":
        GraphTextColor = "#333";
        return "#ec394d";
      case "Purplish Red":
        return "#ec3968";
      case "Brownish Red":
        return "#A52A2A";
      case "Gray":
        GraphTextColor = "#333";
        return "#8f8f8f";
      case "Blue Gray":
        GraphTextColor = "#333";
        return "#8fa3b1";
      case "Purple":
        return "#8c87aa";
      case "Bluish Green":
        return "#6db9a4";
      case "Purple Pink":
        return "#cf29ad";
      case "Brownish Orangy Pink":
        return "#dc7979";
      case "Pink Purple":
        return "#9c4c89";
      case "Gray Violet":
        return "#5a576b";
      case "Grayish Bluish Violet":
        return "#887788";
      case "Grayish Violetish Blue":
        return "#476b8e";
      case "Violet Gray":
        return "#656371";
      case "Violetish Gray":
        return "#656371";
      case "Grayish Violet":
        return "#5a576b";

      default:
        GraphTextColor = "#1B2653";
    }
  };

  return (
    <div>
     

      <Helmet>
        <title>FCRF - Argyle Report</title>
        <meta property="og:title" content="Argyle Report"/>
        <meta property="og:description" content="your Argyle Report"/>
        <meta property="og:image" content={`${clientPath}/images/diamond-${metaImgData.color}-${metaImgData.shape}.svg`} />
      </Helmet>
      {loading && <Loader type={"progress"} />}
      <div className="argyle-report-page rarity-report-page shared-rarity-report-page shared-argyle-report-page argyle-report-update-page">
        <div className="rarity-report-head">
          <div className="container rarity-argyle-container">
          
            <div className="report-head-content mb-3">
            
            </div>
           
          </div>
        </div>
        <div className="rarity-report-content">
          <div className="container rarity-argyle-container">
            <div className="rarity-tool">
              <div className="rarity-tool-grid">
                <div className="rarity-tool-left">
                  <GemologicalCharacteristics
                    report={reportData}
                    barColor={color() || "#f1efef"}
                    GraphTextColor={GraphTextColor}
                  />
                </div>
                <div className="rarity-tool-right">
                  <DiamondRare report={reportData} colorCode={color()} qrCodeUrl={qrCodeUrl} />
                </div>
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareRarityReport;
