import { useContext } from "react";
import Form from "react-bootstrap/Form";
import FcrfContext from "../../Context";
import { ArgyleDiamonds } from "../../utils/argyleDiamondsDetail";

const DiamondColor = ({ payload, handleOnchange }) => {
  const getContext = useContext(FcrfContext);
  const { rarityColor, setRarityColor } = getContext;

  return (
    <div
      className="diamond-color-wrapper diamond-weight-wrapper"
      id="diamondColor"
    >
      <h5>Select the color of the diamond:</h5>
      <div className="diamond-color-grid">
        {ArgyleDiamonds.map((el, i) => (
          <Form.Group className="diamond-btn-wrapper" key={i}>
            <input
              type="radio"
              id={`${el?.color?.value}`}
              name="color"
              title={el?.color?.value}
              value={payload?.color}
              onClick={() =>
                setRarityColor({ ...rarityColor, color: el?.color?.img })
              }
              onChange={(e) =>
                handleOnchange(el?.color?.value, e.target.name, 2)
              }
              checked={payload?.color === el?.color?.value}
            />
            <div className="diamond-color-box">
              <img
                src={`images/diamond-${el?.color?.img}-general.svg`}
                alt={el?.color?.img}
              />
              <span>{el?.color?.value}</span>
            </div>
          </Form.Group>
        ))}
      </div>
    </div>
  );
};

export default DiamondColor;
