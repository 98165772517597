import { useEffect, useRef, useState, useMemo, useContext } from "react";
import FcrfContext from "../../Context";


const dummyData = [
  {
    name: "FL or IF",
    data: "Flawless / Internally flawlesss",
  },
  {
    name: "VVS",
    data: "Very Very Slightly Included",
  },
  {
    name: "VS",
    data: "Very Slightly Included",
  },
  {
    name: "SI1",
    data: "Slightly Included",
  },
  {
    name: "SI2",
    data: "Slightly Included",
  },
  {
    name: "I",
    data: "Inclusion or Pique",
  },
];

const DiaryClarity = ({
  clarityInfo,
  color,
  textColor,
  sidebarCard,
  handleSideBarCard,
  isHide
}) => {
  const {setPdfReportData} = useContext(FcrfContext);
  const clarityBox = useRef(null);
  const [viewOnce, setViewOnce] = useState(false);

  const isInViewport = useIsInViewport(clarityBox);

  setTimeout(() => {
    if (isInViewport) {
      setViewOnce(true);
    }
  }, 1000);

  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }

  const clarityInfoText = clarityInfo?.C38.split("#") || [];

  useEffect(() => {
    const clarityBox = {
      heading: clarityInfo?.B36,
      clarityInfoText: clarityInfoText?.length> 0 ? clarityInfoText:null,
      clarityInfoB38: clarityInfo?.B38,
    };
    setPdfReportData((prev)=>({...prev, clarityBox}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clarityInfo ]);

  return (
    <section
      className={`diary-card ${sidebarCard?.clarityBox ? "show" : "hide"}`}
      id="clarityBox"
    >
      {!isHide &&
      <div className="mobile-hide-card-btn">
        <label className="diary-check-container">
          <input
            type="checkbox"
            id={`nav-clarityBox`}
            name={`clarityBox`}
            onChange={() =>
              handleSideBarCard("clarityBox", sidebarCard?.clarityBox)
            }
            checked={sidebarCard?.clarityBox}
          />
          <div className="checkmark"></div>
        </label>
      </div>
}
      <h4 className="title-long-width">{clarityInfo?.B36}</h4>
      <div className="diary-card-content clarity-card-content">
        <p>
          {clarityInfoText[0]} <b>{clarityInfoText[1]}</b> {clarityInfoText[2]}
          <b>{clarityInfoText[3]}</b> {clarityInfoText[4]}
          <b>{clarityInfoText[5]}</b> {clarityInfoText[6]}
          <b>{clarityInfoText[7]}</b> {clarityInfoText[8]}
          <b>{clarityInfoText[9]}</b> {clarityInfoText[10]}
          <b>{clarityInfoText[11]}</b> {clarityInfoText[12]}
          <b>{clarityInfoText[13]}</b> {clarityInfoText[14]}
          <b>{clarityInfoText[15]}</b> {clarityInfoText[16]}
          <b>{clarityInfoText[17]}</b> {clarityInfoText[18]}
        </p>
        <div className="clarity-grid-wrapper" ref={clarityBox}>
          {dummyData.map((el, i) => (
            <div
              className={`clarity-box-wrapper ${
                el?.name === clarityInfo?.B38 && viewOnce ? "active" : ""
              }`}
              style={{
                backgroundColor:
                  el?.name === clarityInfo?.B38 && viewOnce
                    ? `${color}`
                    : "#fff",
              }}
              key={i}
            >
              <h4>{el.name === "FL or IF" ? "F/IF" : el.name}</h4>
              <span
                style={{
                  color:
                    textColor !== "" &&
                    el?.name === clarityInfo?.B38 &&
                    viewOnce
                      ? textColor
                      : "#6a77a2",
                }}
              >
                {el.data}
              </span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DiaryClarity;
