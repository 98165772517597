import React, { useContext, useEffect, useRef, useState } from "react";
import FcrfContext from "../../Context";
import Reel from "react-reel";
import { useInView } from "react-intersection-observer";
let count = 1;

const DiaryRarity = ({
  rarityInfo,
  data,
  color,
  goToRairtyReport,
  sidebarCard,
  handleSideBarCard,
  isHide,
  stringDate,
  
}) => {
  const { setPdfReportData } = useContext(FcrfContext);
  const rarityText1 = rarityInfo?.C64?.split("#") || [];
  const rarityText2 = rarityInfo?.C66?.split("#") || [];
  const [animateText, setAnimateText] = useState(Number(rarityInfo?.E64) - 5);
  const [gradeAnimate, setGradeAnimate] = useState(false);
  const gemologicalRef = useRef();
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  });
  //diaryPayload, setdiaryPayloads
  useEffect(() => {
    const rarityBox = {
      heading: rarityInfo?.B62,
      rarityText1: rarityText1?.length > 0 ? rarityText1 : null,
      rarityText2: rarityText2?.length > 0 ? rarityText2 : null,
      rarityGrade: rarityInfo?.C68 ? rarityInfo?.C68 : "",
      rarityLastUpdate: `${rarityInfo?.D68} ${rarityInfo?.E68}`,
    };
    setPdfReportData((prev) => ({ ...prev, rarityBox }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rarityInfo]);

  

  const boldText = rarityText1[1]?.split(" ");
 

  useEffect(() => {
    setAnimateText(Number(rarityInfo?.E64) - 5)
    if (inView && rarityInfo?.E64) {
      
     const interval = setInterval(() => {
        if (count <= 5  &&animateText<=rarityInfo?.E64  ) {
           setAnimateText((old) => old + 1);
          count++;
          
         } else if (count === 6) {
         
          setGradeAnimate(true);
          
          clearInterval(interval);
          }
   }, 1000);
   return () => {
        clearInterval(interval);
      };
     
    }
    // eslint-disable-next-line
  }, [inView,rarityInfo?.E64]);
  

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const theme = {
    reel: {
      height: "16px",
      display: "flex",
      alignItems: "flex-end",
      overflowY: "hidden",
      fontSize: "16px",
      fontWeight: "600",
      color: "#181f37",
      lineHeight: "17px",
    },
    group: {
      transitionDelay: "0ms",
      transitionTimingFunction: "ease-in-out",
      transform: "translate(0, 0)",
      height: "16px",
    },
    number: {
      height: "16px",
    },
  };

  const getReelTheme = () => ({
    ...theme,
    reel: {
      ...theme.reel,
    },
  });
  return (
    <section
      className={`diary-card ${sidebarCard?.rarityBox ? "show" : "hide"}`}
      id="rarityBox"
    >
      {!isHide && (
        <div className="mobile-hide-card-btn">
          <label className="diary-check-container">
            <input
              type="checkbox"
              id={`nav-rarityBox`}
              name={`rarityBox`}
              onChange={() =>
                handleSideBarCard("rarityBox", sidebarCard?.rarityBox)
              }
              checked={sidebarCard?.rarityBox}
            />
            <div className="checkmark"></div>
          </label>
        </div>
      )}
      <div>
       
        <div className="diary-card-content polished-card-content">
          <div className="rarity-general">
            <div className="rarity-info-des">
              <h4 className="rarity-title">{rarityInfo?.B62}</h4>
              <div className="diary-rarity text wrapper">
                <div  ref={(node) => {
        gemologicalRef.current = node;
        inViewRef(node);
      }} className="diary-rarity-text-reel-wrapper">
                  {rarityText1[0]}
                  <b>
                    {boldText[0]} {boldText[1]} {boldText[2]}
                  </b>
                  <span>
                    <Reel
                      theme={getReelTheme()}
                      text={`${numberWithCommas(animateText)}`}
                    />
                  </span>

                  {rarityText1[2]}
                </div>
                <p>
                  {rarityText2[0]}
                  <b>{rarityText2[1]}</b>
                  {rarityText2[2]}
                </p>
              </div>
            </div>
           
          </div>

         
          <div className="rarity-grade-wrapper">
            <div className="rarity-grade">
              <div className="rarity-grade-text">
                <p>Rarity Grade</p>
               
                <span
                  className={`grade-info-text ${gradeAnimate ? "animate" : ""}`}
                >
                  {rarityInfo?.C68}
                </span>
              </div>
            </div>
            <div className="rarity-grade-point-wrapper">
            <span className="text-url">
              <span
                className="rarity-link rarity-text-link"
                onClick={() => goToRairtyReport()}
                role="presentation"
              >
                Explore the full rarity report
              </span>
             
            </span>
            
            </div>
          </div>
         
        </div>
      </div>
    </section>
  );
};

export default DiaryRarity;
