import Form from "react-bootstrap/Form";
import { DiamondClaritys } from "../../utils/diamondsDetail";

const DiamondClarity = ({ payload, handleOnchange }) => {
  return (
    <div
      className="diamond-clarity-wrapper diamond-color-wrapper diamond-weight-wrapper"
      id="diamondClarity"
    >
      <h5>Select the clarity of the diamond:</h5>
      <div className="diamond-shape-grid">
        {DiamondClaritys.map((el, i) => (
          <Form.Group className="diamond-btn-wrapper" key={i}>
            <input
              type="radio"
              id={`diamond${el?.diamondType[0]}`}
              name="clarity"
              value={payload?.clarity}
              onChange={(e) =>
                handleOnchange(el?.diamondType[0], e.target.name, 5)
              }
              checked = {payload?.clarity === el?.diamondType[0]}
            />
            <div className="diamond-color-box">
              <div className="diamond-clarity-type">
                <h6>{el?.diamondType[0]}</h6>
                {el?.diamondType.length > 1 && <span>OR</span>}
                <h6>{el?.diamondType[1]}</h6>
              </div>
              <span>{el?.desc}</span>
            </div>
          </Form.Group>
        ))}
      </div>
    </div>
  );
};

export default DiamondClarity;
