import React from "react";
import { getColorCode } from "../../utils/graphColor";
import { useSpring, animated } from "react-spring";

export const ChartData = ({ color, value, label }) => {
  const chartDataSpring = useSpring({
    from: { width: "0%" },
    to: { width: `${value || 0}%` },
    config: { duration: 2000 },
  });

  return (
    <animated.div className="custom-chart-section-wrapper">
      <span className="custom-chart-label">{label || ""}</span>
      <div className="custom-chart-main">
        <div className="custom-chart-ruler">
          <div className="custom-chart-ruler-point">0%</div>
          <div className="custom-chart-ruler-point custom-chart-point-25"></div>
          <div className="custom-chart-ruler-point">50%</div>
          <div className="custom-chart-ruler-point custom-chart-point-75"></div>
          <div className="custom-chart-ruler-point">100%</div>
        </div>
        <div className="custom-chart-progress-wrapper">
          <animated.div
            className="custom-chart-progress"
            style={{ background: color, width: chartDataSpring.width }}
          ></animated.div>
        </div>
      </div>
    </animated.div>
  );
};

const MarketDistribution = ({
  graph_texts,
  labelName,
  labelValue,
  loading,
}) => {
  const color = getColorCode(labelName[0]);

  return (
   
    labelValue.map((value, i) => (
      <ChartData
        label={graph_texts[i]}
        color={color}
        value={value}
        key={i}
      />
    ))
   
  );
};

export default MarketDistribution;
