import React from "react";
import Modal from "react-bootstrap/Modal";
import { wpPath } from "../../config/key";
import { Button } from "react-bootstrap";


const DiaryNotice = ({ show, setShow, type,handleDelete,reportType, giaNumber }) => {
 
  return (
    <>
      <Modal
        className="diary-notice-modal"
        show={show}
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <>
            {(type === "colorshape" || type === "preventreport") && (
              <p>
                This beta version does not support some of the information about this diamond.<br/> Please{" "}
                <a
                  href={`${wpPath}/special-request-for-diary-report?ReportType=${reportType==='rarity'? "Rarity":"Diary"}&GiaNumber=${giaNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  send us a private request
                </a>{" "}
                and we will do our best to provide you with a report. Thank you.
              </p>
            )}
            {type === "unauthorized" && (
              <p>
                 Report not found due to some issues, please search again!
              </p>
            )}
             {type === "natural" && (
              <p>
                {reportType==="diary"&&" The Diary Report only supports fancy color diamonds."}
                
               
              </p>
            )}

           {type === "notnatural" && (
              <p>
                {
                  reportType==="diary"?"The Diamond Diary only supports natural fancy color diamonds.":"The Rarity Report only supports natural fancy color diamonds."
                }
                 
                
              </p>
            )}
        
             {(type === "notsupported" &&reportType==="rarity") &&<p>The Rarity Report only supports fancy color diamonds with a full GIA report.</p>}
             {(type === "notsupported" &&reportType==="diary") &&<p>The Diary Report only supports fancy color diamonds.</p>}
            {(type === "notfound" ) && <p>{reportType==="diary"?"The Diary Report only supports fancy color diamonds":"The Rarity Report only supports fancy color diamonds"}</p>}
            {type === "cartlessthan" && (
              <p>
                {reportType==="diary"?"We apologize, but the Diary Report only supports diamonds that are 0.18 carats and above.":"The Rarity Report supports diamonds that are between 0.18 - 99 carats."}
              </p>
            )}

{type === "cartgraterthan" && (
              <p>
                {reportType==="diary"?"We apologize, but the Diary Report only supports diamonds that are 0.18 carats and above.":"The Rarity Report supports diamonds that are between 0.18 - 99 carats."}
              </p>
            )}

{type === "Search for a GIA number that does not exist in GIA" && <p>This report number doesn't exist in the GIA database. Please verify the number and try again</p>}

            
           
            {type === "delete" && (
              <div className="diary-delete-modal">
              <p>
                 Are you sure you want to delete?
              </p>
              <div className="delete-modal-btn"><Button className="no-outline-btn" onClick={()=>handleDelete()}>Confirm</Button>
              <Button className="outline-btn" onClick={()=>setShow(false)}>Cancel</Button></div>
              </div>
            )}
            {type === "hideRarity" && (
              <p>
               The rarity report only supports diamonds with a clarity grade.
              </p>
            )}
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DiaryNotice;
