import React from "react";
import "./modal.css";

const SaveNotificationModal = ({ message, undoBtn }) => {
  return (
    <div className="save-notification-wrapper">
      <p>{message}</p>
      {undoBtn ? <span>undo</span> : ""}
    </div>
  );
};

export default SaveNotificationModal;
