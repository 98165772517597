import React from "react";
import Modal from "react-bootstrap/Modal";

const OriginYoutubeModal = ({ originModal, setOriginModal, originInfo }) => {
  const youTubeLink = originInfo?.E46?.split("=")[1];
  return (
    <Modal
      className="diary-notice-modal diary-origin-video-modal"
      show={originModal}
      onHide={() => setOriginModal(false)}
      centered
      size="lg"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <iframe
          width="100%"
          height="500"
          id="originYoutubeVideo"
          src={`https://www.youtube.com/embed/${youTubeLink}?autoplay=1`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </Modal.Body>
    </Modal>
  );
};

export default OriginYoutubeModal;
