import React from "react";
import { Button, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const DisclaimerPopup = ({
  show,
  setShow,
  disclaimerInput,
  setDisclaimerInput,
  updateDisclaimer,
  disclaimerError,
  setDisclaimerError,
  btnLoading
}) => {
  const handleClose = () => {
    
    setShow(false);
    setDisclaimerInput(false);
    setDisclaimerError("");
  };
  return (
    <>
      <Modal
        className="diary-notice-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <p>
            The data on the Fancy Color Diamond Diary© may change from time to
            time and is not a guarantee or a reflection of the diamond's value.
            The origin of the diamond is provided solely according to the input
            of the person for whom the report was produced. The gemological
            description of the diamond is obtained from the GIA website. To
            maintain the accuracy and currency of the information, it is advised
            to periodically update the diary, especially prior to each use. For
            additional information about the data and other limitations, please
            contact us at{" "}
            <a href="mailto:customers@fcresearch.org">
            customers@fcresearch.org
            </a>
            .
          </p>
          <div className="disclaimer-btn-wrapper">
            <label className="disclaimer-label">
              <input
                type="checkbox"
                id={`nav-indexBox`}
                name="disclaimer"
                onChange={(e) => setDisclaimerInput(e.target.checked)}
              />
              <div className="disclaimer-checkmark"></div>I agree to the terms
              of use
            </label>
           

            <Button
                    className="disclaimer-btn"
                    onClick={() => {
                      updateDisclaimer();
                    }}
                    disabled={!disclaimerInput || btnLoading}
                  >
                    {btnLoading ? (
                      <Spinner animation="border" />
                    ) : (
                      <>
                       
                        <span>Accept and continue</span>
                      </>
                    )}
                  </Button>
          </div>
          {disclaimerError && (
            <p className="text-danger text-center">{disclaimerError}</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DisclaimerPopup;
