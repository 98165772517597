import { useContext } from "react";
import Form from "react-bootstrap/Form";
import FcrfContext from "../../Context";
import { DiamondColors } from "../../utils/diamondsDetail";

const DiamondColor = ({ payload, handleOnchange }) => {
  const getContext = useContext(FcrfContext);
  const { rarityColor, setRarityColor } = getContext;

  return (
    <div
      className="diamond-color-wrapper diamond-weight-wrapper new-diamond-color-wrapper"
      id="diamondColor"
    >
      <h5>Select the color of the diamond:</h5>
      <div className="diamond-color-grid">
        {DiamondColors.map((el) => (
          <Form.Group className="diamond-btn-wrapper" key={el?.id}>
            <input
              type="radio"
              id={`${el?.value}`}
              name="color"
              value={payload?.color}
              title={el?.value}
              onClick={() => setRarityColor({ ...rarityColor, color: el?.img })}
              onChange={(e) => handleOnchange(el?.value, e.target.name, 2)}
              checked={payload?.color === el?.value}
            />
            <div className="diamond-color-box">
              <img
                src={`images/diamond-${el?.img}-general.svg`}
                alt={el?.img}
              />
              <span>{el?.name ? el?.name : el?.img}</span>
            </div>
          </Form.Group>
        ))}
      </div>
    </div>
  );
};

export default DiamondColor;
