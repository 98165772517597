import React, { useState, useRef, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { wpPath } from "../../config/key";
import { Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import {  withAuth } from "../../config/axios";
import { toast } from "react-toastify";
import "./modal.css";
import FcrfContext from "../../Context";
//import { publicIpv4 } from "public-ip";

const DiaryRenameDelete = ({
  show,
  popupData,
  handleClosePopup,
  handleClosePopupAfterSuccess,
  reportType,
  from,
}) => {
  const getContext = useContext(FcrfContext);
  const { setSavedNotification } = getContext;
  const [isActive, setisActive] = useState(false);
  const [recordName, setrecordName] = useState("");
  const [errorName, seterrorName] = useState(null);
  const [copyrecordName, setcopyRecordName] = useState("");

  const handlerecordName = (e) => {
    seterrorName(null);
    setrecordName(e.target.value);
  };

  const handleRenameSubmit = async (e) => {
    e.preventDefault();
    if (recordName === null || recordName === undefined || recordName === "") {
      seterrorName("Enter a Record Name");
      return;
    }
    if (recordName && recordName.trim() === "") {
      seterrorName("Enter a Record Name");
      return;
    }

    setisActive(true);
    const { reportId } = popupData;
    try {
      //const ipAddress = await publicIpv4();
      //console.log("ipAddress =>", ipAddress) copyrecordName
      const renameStatus = copyrecordName === recordName ? false : true;

      const response = await withAuth().post(`${reportType}/renameReport`, {
        reportId,
        recordName,
        from: renameStatus,
      });
      const {
        data: { status, msg, data },
      } = response || {};
      if (status === 200) {
        setisActive(false);
        setSavedNotification(true);
        setTimeout(() => {
          setSavedNotification(false);
        }, 5000);
        handleClosePopupAfterSuccess(reportId, msg, data);
      }
    } catch (err) {
      setisActive(false);
      toast.error(err?.response?.data?.msg || "Internal server error", {
        toastId: "Rename Report",
      });
    }
  };

  const handleDelete = async () => {
    setisActive(true);
    try {
      const response = await withAuth().delete(
        `${reportType}/deleteReportById/${popupData.reportId}/${from}`
      );
      const {
        data: { status, msg },
      } = response || {};
      if (status === 200) {
        setisActive(false);
        setSavedNotification(true);
        setTimeout(() => {
          setSavedNotification(false);
        }, 5000);

        handleClosePopupAfterSuccess(popupData.reportId, msg);
      }
    } catch (err) {
      setisActive(false);
      toast.error(err?.response?.data?.msg || "Internal server error", {
        toastId: "DeleteId",
      });
    }
  };

  useEffect(() => {
    setrecordName(popupData.recordName);
    setcopyRecordName(popupData.recordName);
  }, [popupData]);

  return (
    <>
      <Modal
        className="diary-notice-modal"
        show={show}
        onHide={handleClosePopup}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <>
            {(popupData.type === "colorshape" ||
              popupData.type === "preventreport") && (
              <p>
                This beta version does not support some of the information about
                this diamond. please send us a private request{" "}
                <a
                  href={`${wpPath}/special-request-for-diary-report/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here.
                </a>{" "}
                and we will do our best to provide you with a report. Thank you
              </p>
            )}
            {popupData.type === "unauthorized" && (
              <p>Report not found due to some issues, please search again!</p>
            )}
            {popupData.type === "natural" && (
              <p>
                The diamond diary only supports fancy color diamonds with a
                natural origin of color and a full GIA report.
              
              </p>
            )}
           
            {popupData.type === "notfound" && <p>Report not found</p>}
            {popupData.type === "cartlessthan" && (
              <p>
                We apologize, but the Diamond diary only supports diamonds that
                are 0.18 carats and above.
              </p>
            )}

           
            <>
              {popupData.type === "delete" && (
                <DeleteComponent
                  popupData={popupData}
                  isActive={isActive}
                  handleDelete={handleDelete}
                  handleClosePopup={handleClosePopup}
                />
              )}
              {popupData.type === "rename" && (
                <RenameComponent
                  isActive={isActive}
                  popupData={popupData}
                  recordName={recordName}
                  errorName={errorName}
                  handlerecordName={handlerecordName}
                  handleRenameSubmit={handleRenameSubmit}
                />
              )}
            </>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DiaryRenameDelete;

const DeleteComponent = ({
  popupData,
  isActive,
  handleDelete,
  handleClosePopup,
}) => {
  return (
    <>
      <div className="diary-delete-modal ">
        {popupData.type === "delete" && <p>Are you sure you want to delete?</p>}
        <div className="delete-modal-btn">
          <Button
            className="no-outline-btn"
            disabled={isActive}
            onClick={handleDelete}
          >
            {" "}
            {isActive ? <Spinner animation="border" /> : "Delete"}
          </Button>
          <Button className="outline-btn" onClick={handleClosePopup}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

const RenameComponent = ({
  isActive,
  recordName,
  errorName,
  handlerecordName,
  handleRenameSubmit,
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <>
      <Form className="diary-card rename-form" onSubmit={handleRenameSubmit}>
        <h5>Report Name</h5>
        <Form.Group
          className={`diamond-weight diary-input`}
          controlId="formBasicEmail"
        >
          <Form.Label>Enter Report Name</Form.Label>

          <Form.Control
            type="text"
            name="record_name"
            value={recordName}
            onChange={handlerecordName}
            ref={inputRef}
            autoFocus={true}
            onWheel={(e) => e.currentTarget.blur()}
          />
        </Form.Group>
        {errorName && <span className="error-text">{errorName}</span>}
        <Button
          className="no-outline-btn bg-btn"
          type="submit"
          disabled={isActive}
        >
          {isActive ? <Spinner animation="border" /> : "RENAME REPORT"}
        </Button>
       
      </Form>
    </>
  );
};
