import { useContext } from "react";
import Form from "react-bootstrap/Form";
import FcrfContext from "../../Context";
import { ArgyleDiamonds } from "../../utils/argyleDiamondsDetail";

const DiamondSaturation = ({ payload, handleOnchange }) => {
  const getContext = useContext(FcrfContext);
  const { rarityColor } = getContext;

  const result = ArgyleDiamonds.filter(
    (elem) =>
      rarityColor.color === elem.color.img &&
      payload?.color === elem.color.value
  );
  const DiamondSaturations = result[0]?.saturation;

  return (
    <div
      className="diamond-color-wrapper diamond-weight-wrapper diamond-saturation-wrapper"
      id="diamondSaturation"
    >
      <h5>Select the saturation of the diamond:</h5>
      <div className="diamond-shape-grid">
        {DiamondSaturations?.map((el, i) => (
          <Form.Group className="diamond-btn-wrapper" key={i}>
            <input
              type="radio"
              id={`${el}`}
              name="saturation"
              title={el}
              value={payload?.saturation}
              onChange={(e) => handleOnchange(el, e.target.name, 4)}
              checked={payload?.saturation === el}
            />
            <div className="diamond-color-box">
              <img
                className={`diamond-saturation ${el}`}
                src={`images/diamond-${rarityColor.color}-${rarityColor?.shape}.svg`}
                alt={el}
              />
              <span>{el}</span>
            </div>
          </Form.Group>
        ))}
      </div>
    </div>
  );
};

export default DiamondSaturation;
