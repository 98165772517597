import { useContext } from "react";
import Form from "react-bootstrap/Form";
import FcrfContext from "../../Context";
import { ArgyleDiamonds } from "../../utils/argyleDiamondsDetail";

const DiamondShape = ({ payload, handleOnchange }) => {
  const getContext = useContext(FcrfContext);
  const { rarityColor, setRarityColor } = getContext;

  const result = ArgyleDiamonds.filter(
    (elem) => rarityColor.color === elem.color.img
  );
  const DiamondShape = result[0]?.shape;

  return (
    <div
      className="diamond-color-wrapper diamond-weight-wrapper diamond-shape-wrapper"
      id="diamondShape"
    >
      <h5>Select the shape of the diamond:</h5>
      <div className="diamond-shape-grid">
        {DiamondShape.map((el) => (
          <Form.Group className="diamond-btn-wrapper" key={el?.id}>
            <input
              type="radio"
              id={el?.value}
              name="shape"
              title={el?.value}
              value={payload?.shape}
              onClick={() => setRarityColor({ ...rarityColor, shape: el?.img })}
              onChange={(e) => handleOnchange(el?.value, e.target.name, 3)}
              checked={payload?.shape === el?.value}
            />
            <div className="diamond-color-box">
              <img
                src={`images/diamond-${rarityColor.color}-${el?.img}.svg`}
                alt={el?.img}
              />
              <span>{el?.value}</span>
            </div>
          </Form.Group>
        ))}
      </div>
    </div>
  );
};

export default DiamondShape;
