import React, { useState, useContext, useEffect } from "react";
import SettingLayout from "./settingLayout";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import {
  SidebarMenu,
  initialSidebarFalse,
  sidebarMenuSliceNum,
  initialSidebar,
  getDefaultSideBarMenuArray,
} from "../../utils/sidebarMenuItems";
import TickImg from "../../assets/images/tick.svg";
import "./setting.css";
import FcrfContext from "../../Context";
import {  withAuth } from "../../config/axios";
import SaveNotificationModal from "../Models/saveNotificationModal";
import leftArrow from "../../assets/images/rarity/icon-arrow-left.svg";
import SettingModal from "./settingModal";
import fetchUserSettings from "../../utils/commonfunctions/fetchUserSettings";
import getlastUpdate from "../../utils/commonfunctions/getlastUpdate";

const DiarySetting = () => {
  const [loader, setLoader] = useState(true);
  const { sidebarCardMainCopy, setSidebarCardMainCopy, setSidebarCard,  companyLogo, setCompanyLogo,setIsWp,
   
          setis_rarity_cradits,
         
          setis_credits_included,
         
          setis_diary_balance,
          setDateSetting
  
  } =
    useContext(FcrfContext);
  const [sidebarCardData, setSidebarCardData] = useState(sidebarCardMainCopy);
  const [loading, setLoading] = useState(false);
  const [showWarningMsg, setShowWarningMsg] = useState(false);
  const [toggleViewSaveButton, setToggleViewSaveButton] = useState(false);
  const [imageuploadChanged, setImageUploadChnaged] = useState(false);
  const [natification, setNotification] = useState();
  const [natificationlogo, setNotificationlogo] = useState(false);
  const[custom_branding,setCustom_branding]=useState("")
  const [imgdata, setImgData] = useState(null);
  const [, setuploadedImageFile] = useState([])
  const [confirmImageChange, setConfirmImageChange] = useState(false);
  const [show, setShow] = useState(false);
  const [imageFile , setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const getSettingUpdateAction = ()=>{
    let actionValue = "" 
    if(toggleViewSaveButton && imgdata && imageuploadChanged){
      actionValue = "Category and Company Logo update"
    }else if(toggleViewSaveButton){
      actionValue = "Category update"
    } else if(imgdata && imageuploadChanged){
      actionValue = "Company Logo update"
    }
    return actionValue
  }
  const handleImageUploadChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && e.target.files.length > 0) {
   
      setImageFile(selectedFile);
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        setImageUrl(e.target.result); // Set the preview URL
      };
      fileReader.readAsDataURL(selectedFile);
    } else {
      setImgData(null); 
      setImageUploadChnaged(false); 
      
    }
  };

  useEffect(() => {
    if (loader) {
      const interval = setInterval(() => {
        window.parent.postMessage("iframeIsReadyToReceiveUserData", "*");
      }, 1000);
  
      const handleMessage = event => {
       
        console.log("event.origin", event.origin);
        console.log("event.origin2", process.env.REACT_APP_WP_BASE_URL);
        if (event.origin === process.env.REACT_APP_WP_BASE_URL || event.origin === "https://dev.fcresearch.org") {
          const {
            is_free,
            token,
            is_wp,
            is_rarity_cradits,
            is_credits_included,
            is_diary_balance,
          } = event?.data;

          setIsWp(is_wp);
          console.log("token come form wp diary==>", token);
          console.log("event?.data==>", event?.data);

          if (token) {
            setis_diary_balance(is_diary_balance);
            setis_credits_included(is_credits_included);
            setis_rarity_cradits(is_rarity_cradits);
            localStorage.setItem("is_free", is_free ? is_free : false);
            localStorage.setItem("fcrf@token", token);
            fetchSettings();
            setLoader(false);
          } else {
            localStorage.removeItem("fcrf@token");
          }
        } else {
          if (!localStorage.getItem("fcrf@token")) {
            if (window.location !== window.parent.location) {
              // The page is in an iframe
            } else {
              // The page is not in an iframe
              window.location.href = process.env.REACT_APP_WP_APP_LOGIN;
            }
          }
        }

        if (event?.data) {
          clearInterval(interval);
        }
      };

      window.addEventListener("message", handleMessage, false);

      return () => {
        window.removeEventListener("message", handleMessage);
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader]);
  const fetchSettings = async () => {
    const { diarySettingsObj, companyLogoURL, custom_branding } =
      await fetchUserSettings();
    if (custom_branding === "1") {
      setCustom_branding(custom_branding);
    }
    const { data } = await getlastUpdate();
    if (data) {
      setDateSetting(data);
    }
    if (diarySettingsObj && Object.keys(diarySettingsObj).length > 0) {
      setSidebarCard(diarySettingsObj);
      setSidebarCardMainCopy(diarySettingsObj);
    }
    if (companyLogoURL) {
      setCompanyLogo(companyLogoURL);
    }
  };
  
  

  const showHideErrorMsg = (obj1, obj2) => {
    //
    // Show msg if there is any difference in previous saved record and new changes otherwise do not show msg
    //
    const checkChange = JSON.stringify(obj1) !== JSON.stringify(obj2);
    const checkAllFalse = Object.values(obj2)?.filter((el) => el);
    if (checkAllFalse.length === sidebarMenuSliceNum) {
      setShowWarningMsg(true);
      setToggleViewSaveButton(false);
    } else if (checkChange) {
      setShowWarningMsg(true);
      setToggleViewSaveButton(true);
    } else {
      setShowWarningMsg(false);
      setToggleViewSaveButton(false);
    }
  };

  const handleSelectCard = (id, value) => {
    const copySideBarData = { ...sidebarCardData };
    copySideBarData[id] = !value;
    setSidebarCardData(copySideBarData);
    showHideErrorMsg(sidebarCardMainCopy, copySideBarData);
  };

  const handleReset = () => {
    setSidebarCardData(initialSidebar);
    showHideErrorMsg(sidebarCardMainCopy, initialSidebar);
  };

  const handleUnselectCard = () => {
    //
    // if there is need to set a key true even unselect all
    //
    const initialSidebar = { ...initialSidebarFalse, giaSummeryBox: true };
    setSidebarCardData(initialSidebar);
    showHideErrorMsg(sidebarCardMainCopy, initialSidebar);
    setToggleViewSaveButton(false);
  };

 

  useEffect(() => {
    setSidebarCardData(sidebarCardMainCopy);
  }, [sidebarCardMainCopy]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      // Get the array of strings to send which are show/hide settings
      
      const { displaySettings, hiddenSettings } =
        getDefaultSideBarMenuArray(sidebarCardData);

      // Required payload options need to sent with uppdate settings
      // start=>
      const product_id = localStorage.getItem("product_id");
      const product_name = localStorage.getItem("item_name");
      // <=End
      const formData = new FormData();
      const diarySettingData = {
        diarySettings: sidebarCardData,
        categories: { displaySettings, hiddenSettings },
        // Required options Start =>>>
        action: getSettingUpdateAction(),
        userProductDetails: {
          product_id: product_id ? product_id : "",
          product_name: product_name ? product_name : "",
        },
        //<===End
      }
      formData.append("diarySettingData", JSON.stringify(diarySettingData));
      console.log('imgdata----',imgdata)
      if( imageFile && imageUrl){
        formData.append("files", imageFile, "company.png");
      }
      const updateDiarySettings = await withAuth().put("/users/setting",formData);
      //
      // for show loader more 400ms
      //

      setTimeout(() => {
        setLoading(false);
        setShowWarningMsg(false);
      }, 400);
      if (updateDiarySettings?.data?.status === 200) {
        setNotification(true);
        setImageUploadChnaged(false); 
        setConfirmImageChange(false)
        setTimeout(() => {
          setNotification(false);
        }, 3000);
        setSidebarCard(sidebarCardData);
        setSidebarCardMainCopy(sidebarCardData);
        setImageFile(null)
        const uploadedImage = updateDiarySettings?.data?.updateUserSettings?.companylogo
        if(imgdata && confirmImageChange && uploadedImage){
          setCompanyLogo(uploadedImage)
          setImgData(null)
        }
      }
    } catch (error) {
      console.log('error---',error);
      setLoading(false);
    }
  };

  const imgDeleteHandel=async()=>{
    if(companyLogo && !imgdata){
      // console.log("companyLogo",companyLogo);
        const response=await withAuth().put("/users/UpdateBrandLogo",{
        logo:companyLogo
      });
      console.log(response);
      if(response.data.status===200){
        setNotificationlogo(true)
       setuploadedImageFile([])
       setImageUrl("")
       setImageFile(null)
       setCompanyLogo(response.data.updatedlogo.companylogo)
       setImgData("")
      setShow(!show);
      setTimeout(() => {
        setNotificationlogo(false);
      }, 3000);
      }    
    }
    else{
      setImageFile(null)
       setImageUrl(null)
       setuploadedImageFile([])
       setCompanyLogo('')
       setImgData("")
       setShow(!show);
    }
  }
  const handleClosePopup = () => {
    setShow(!show);
  };
  const handleOpenPopup = () => {
    setShow(!show);
  };

  return (
    <>
    <div className="rarity-header setting-header">
        <div className="rarity-top-header">
          <div className="back-btn" role="presentation">
            <img src={leftArrow} alt="" />
            Settings
          </div>
        </div>
    </div>
    {loader? <div className="settingloader" >
  <div>
    <Spinner animation="border" variant="warning" />
  </div>
</div> :  <SettingLayout>
      <div className='diary-setting-page'>
        <div className='container'>
          <div className='diary-setting-wrapper'>
            <div className='diary-setting-card'>
              <div className='diary-setting-card-header'>
                <h6>default categories</h6>
                <p>Please select which category to show</p>
              </div>
              <div className='diary-setting-btn-wrapper'>
                <div className='diary-setting-btns'>
                  {SidebarMenu?.slice(sidebarMenuSliceNum).map((menu) => (
                    <Button
                      className={`setting-bg-btn ${
                        sidebarCardData[menu?.id] ? "active-btn" : ""
                      }`}
                      onClick={() =>
                        handleSelectCard(menu?.id, sidebarCardData[menu?.id])
                      }
                      key={menu?.id}
                    >
                      {sidebarCardData[menu?.id] && (
                        <img src={TickImg} alt='tick icon' />
                      )}
                      {menu?.title}
                    </Button>
                  ))}
                </div>
                {showWarningMsg && (
                  <div className='diary-setting-btn-info'>
                    <p>
                      <span>Please note:</span> Your default settings will be
                      changed according to the chosen categories.
                    </p>
                  </div>
                )}
              </div>
              <div className='diary-setting-reset-btn-wrapper'>
                <Button className='setting-no-bg-btn' onClick={handleReset}>
                  Reset
                </Button>
                <Button
                  className='setting-no-bg-btn'
                  onClick={handleUnselectCard}
                >
                  Unselect All
                </Button>
              </div>
            </div>
     {custom_branding && custom_branding==="1"?
            <div className='diary-setting-card'>
              <div className='diary-setting-card-header'>
                <h6>Branding</h6>
               
              </div>
              <div className='diary-setting-btn-wrapper'>
              <Form >
               <div className="">
<div className='upload-logo-thumnail'>
<Form.Group className='form-group'>
  <Form.Label className="text-uppercase lbl-space mb-1 ms-2">Upload Logo</Form.Label>
  <div className='upload-game-thumnail'>
  
    <Form.Control
    className="bg-info"
      type='file'
      id='uploadThumbnail'
      name='logo'
      accept='.png'
      onChange={handleImageUploadChange}
    />
    <div className="position-relative  imageboxContainer">
    {!!imageUrl  ||companyLogo!=="" ? 
      <div onClick={()=>{
        if(!loading){
          handleOpenPopup()
        }
        }}  className="position-absolute  overlay top-0 start-100 translate-middle  rounded-circle shadow-sm">
      <Cross/>
    </div>:""
      }
    
    
   
    <Form.Label htmlFor='uploadThumbnail'>
     
      <div className='no-image-area '>
       
        { imageUrl || companyLogo!=="" ? (
          <>
            {' '}
            {  imageUrl ?  (
              <>
                <img
                  src={imageUrl}
                  alt='logo-img'
                />
              </>
            ) : <>
             {companyLogo && (
              <>
                <img src={companyLogo} alt='logo-img' />
              </>
            )}
            </>}
           
          </>
        ) : (
          <>
          <div className="upload-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
          <path d="M5 21C4.45 21 3.979 20.804 3.587 20.412C3.195 20.02 2.99934 19.5493 3 19V5C3 4.45 3.196 3.979 3.588 3.587C3.98 3.195 4.45067 2.99934 5 3H19C19.55 3 20.021 3.196 20.413 3.588C20.805 3.98 21.0007 4.45067 21 5V19C21 19.55 20.804 20.021 20.412 20.413C20.02 20.805 19.5493 21.0007 19 21H5ZM5 19H19V5H5V19ZM6 17H18L14.25 12L11.25 16L9 13L6 17Z" fill="#BF8F03"/>
        </svg>
        </div>
          <h6>
            Drop image here or <span> Upload </span>
          </h6>
          <p className="png-text m-0">PNG Only</p>
          </>
        )}
      </div>
    </Form.Label>
    </div>
  </div>
</Form.Group>
</div>
      </div>
    </Form>
                
              </div>
              
            </div>
:""}
 

{toggleViewSaveButton || imageFile ? (
  <div className='diary-setting-save-wrapper'>
    <Button
      className='no-outline-btn'
      onClick={loading ? null : handleSubmit}
    >
      {loading ? <Spinner animation='border' /> : "Save Changes"}
    </Button>
  </div>
) : null}
          </div>
        </div>
      </div>
      {natification && (
        <SaveNotificationModal message='Diary settings updated successfully' />
      )}

{natificationlogo && (
        <SaveNotificationModal message='Image deleted successfully' />
      )}
    </SettingLayout>}


    <SettingModal
        show={show}
        
        handleClosePopup={handleClosePopup}
        handleClosePopupAfterSuccess={imgDeleteHandel}
      />
    </>
 
  );
};

export default DiarySetting;


const Cross = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 25 24" fill="none">
  <path d="M18.4941 6.00415L6.49414 18.0042" stroke="#212529" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.49414 6.00415L18.4941 18.0042" stroke="#212529" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
};