import React, { useEffect, useRef } from "react";
import DiamondCharacteristics from "./diamondCharacteristics";
import DiamondRare from "./diamondrarity";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { formatDateReport } from "../../utils/dateUtils";
import { clientPath } from "../../config/key";

const ChartData = ({ color, value, label }) => {

  const getColorCode = (color) => {
    let GraphTextColor;
    switch (color) {
      case "Yellow":
        return "#ffda1a";
      case "Greenish Yellow":
        GraphTextColor = "#333";
        return "#c1e51a";
      case "Orange Yellow":
        return "#ffa330";
      case "Pink":
        return "#f563c0";
      case "Orangy Pink":
        return "#dc7979";
      case "Purplish Pink":
        GraphTextColor = "#333";
        return "#cf2985";
      case "Pinkish Purple":
        return "#a07aa0";
      case "Brownish Pink":
        GraphTextColor = "#333";
        return "#de7394";
      case "Brown Pink":
        GraphTextColor = "#333";
        return "#bc5b78";
      case "Blue":
        return "#4380c5";
      case "Gray Blue":
        return "#97bbe0";
      case "Green Blue":
        GraphTextColor = "#333";
        return "#55887e";
      case "Grayish Blue":
        GraphTextColor = "#333";
        return "#8faac5";
      case "Orange":
        GraphTextColor = "#333";
        return "#ff7c31";
      case "Yellow Orange":
        GraphTextColor = "#333";
        return "#fa9922";
      case "Yellowish Orange":
        GraphTextColor = "#333";
        return "#ff8630";
      case "Brown Orange":
        return "#d67f4a";
      case "Green":
        return "#547055";
      case "Yellow Green":
        GraphTextColor = "#333";
        return "#57ee1a";
      case "Blueish Green":
        GraphTextColor = "#333";
        return "#326b72";
      case "Red":
        GraphTextColor = "#333";
        return "#ec394d";
      case "Purplish Red":
        return "#ec3968";
      case "Brownish Red":
        return "#A52A2A";
      case "Gray":
        GraphTextColor = "#333";
        return "#8f8f8f";
      case "Blue Gray":
        GraphTextColor = "#333";
        return "#8fa3b1";
      case "Purple":
        return "#8c87aa";
      case "Bluish Green":
        return "#6db9a4";
      case "Purple Pink":
        return "#cf29ad";
      case "Brownish Orangy Pink":
        return "#dc7979";
      case "Pink Purple":
        return "#9c4c89";
      case "Gray Violet":
        return "#5a576b";
      case "Grayish Bluish Violet":
        return "#887788";
      case "Grayish Violetish Blue":
        return "#476b8e";
      case "Violet Gray":
        return "#656371";
      case "Violetish Gray":
        return "#656371";
      case "Grayish Violet":
        return "#5a576b";

      default:
        GraphTextColor = "#1B2653";
    }

    console.log(GraphTextColor)
  };
  console.log("data", color, getColorCode(color))
  const chartDataSpring = useSpring({
    from: { width: "0%" },
    to: { width: `${value || 0}%` },
    config: { duration: 2000 },
  });




  

  return (
    <animated.div className="custom-chart-section-wrapper">
      <span className="argyle-custom-chart-label">{label || ""}</span>
      <div className="argyle-custom-chart-main">
        <div className="custom-chart-ruler">
          <div className="custom-chart-ruler-point">0%</div>
          <div className="custom-chart-ruler-point custom-chart-point-25"></div>
          <div className="custom-chart-ruler-point">50%</div>
          <div className="custom-chart-ruler-point custom-chart-point-75"></div>
          <div className="custom-chart-ruler-point">100%</div>
        </div>
        <div className="custom-chart-progress-wrapper">
          <animated.div
            className="custom-chart-progress"
            style={{ background: `${getColorCode(color)}`, width: chartDataSpring.width }}
          ></animated.div>
        </div>
      </div>
    </animated.div>
  );
};


const getColorCode = (color) => {
  let GraphTextColor;
  switch (color) {
    case "Yellow":
      return "#ffda1a";
    case "Greenish Yellow":
      GraphTextColor = "#333";
      return "#c1e51a";
    case "Orange Yellow":
      return "#ffa330";
    case "Pink":
      return "#f563c0";
    case "Orangy Pink":
      return "#dc7979";
    case "Purplish Pink":
      GraphTextColor = "#333";
      return "#cf2985";
    case "Pinkish Purple":
      return "#a07aa0";
    case "Brownish Pink":
      GraphTextColor = "#333";
      return "#de7394";
    case "Brown Pink":
      GraphTextColor = "#333";
      return "#bc5b78";
    case "Blue":
      return "#4380c5";
    case "Gray Blue":
      return "#97bbe0";
    case "Green Blue":
      GraphTextColor = "#333";
      return "#55887e";
    case "Grayish Blue":
      GraphTextColor = "#333";
      return "#8faac5";
    case "Orange":
      GraphTextColor = "#333";
      return "#ff7c31";
    case "Yellow Orange":
      GraphTextColor = "#333";
      return "#fa9922";
    case "Yellowish Orange":
      GraphTextColor = "#333";
      return "#ff8630";
    case "Brown Orange":
      return "#d67f4a";
    case "Green":
      return "#547055";
    case "Yellow Green":
      GraphTextColor = "#333";
      return "#57ee1a";
    case "Blueish Green":
      GraphTextColor = "#333";
      return "#326b72";
    case "Red":
      GraphTextColor = "#333";
      return "#ec394d";
    case "Purplish Red":
      return "#ec3968";
    case "Brownish Red":
      return "#A52A2A";
    case "Gray":
      GraphTextColor = "#333";
      return "#8f8f8f";
    case "Blue Gray":
      GraphTextColor = "#333";
      return "#8fa3b1";
    case "Purple":
      return "#8c87aa";
    case "Bluish Green":
      return "#6db9a4";
    case "Purple Pink":
      return "#cf29ad";
    case "Brownish Orangy Pink":
      return "#dc7979";
    case "Pink Purple":
      return "#9c4c89";
    case "Gray Violet":
      return "#5a576b";
    case "Grayish Bluish Violet":
      return "#887788";
    case "Grayish Violetish Blue":
      return "#476b8e";
    case "Violet Gray":
      return "#656371";
    case "Violetish Gray":
      return "#656371";
    case "Grayish Violet":
      return "#5a576b";

    default:
      GraphTextColor = "#1B2653";
  }

  console.log(GraphTextColor)
};




const GemologicalCharacteristics = ({

  report,
  
}) => {
  const { data, range_slider , texts , gemological_text } = report || {};
  const gemologicalRef = useRef();
  const [ text1 , text2, text3 , text4 , text5] = gemological_text || []; 
  console.log("data??!!", report);
  console.log("gemological_text??!!", gemological_text );
  const [, inView] = useInView({
    triggerOnce: true,
  });
  useEffect(() => {
    if (inView) {
      // Trigger animation when in view
      gemologicalRef.current.style.opacity = 1;
      gemologicalRef.current.style.transform = "translateY(0)";
    }
  }, [inView]);


  const today = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <div className="rarity-tool-card rarity-gemological-card argyle-gemological-card argyle_card_geo">
      <div className="rarity-card-content">
       <div className="rarity-report-logo">
          <img
            src={`${clientPath}/icons/FCRF_logo.svg`}
            alt="logo"
          />
        </div>
        <h6 class="date d-none date_block date-text">{months[today.getMonth()]} <span class="lowercase text-dark date-text"> {today.getDate()}th, </span> {today.getFullYear()}</h6>
        <h3>the argyle rarity report</h3>
        <DiamondCharacteristics
          report={report?.data}
          text={texts?.text}
          texts={texts}
         
        />
        <div className="rarity-chart-info">
          <h6>Gemological Characteristics</h6>
          <p>Market Distribution Percentage</p>
        </div>
      </div>
      <div className="rarity-gemological-wrapper">
        <ChartData
          label={`${text1 || ""}`}
          color={data?.color}
          value={range_slider?.color}
        
        />
         <ChartData
          label={`${text2 || ""}`}
          color={data?.color}
          value={range_slider?.saturation}
         
        />
         <ChartData
          label={`${text4 || ""}`}
          color={data?.color}
          value={range_slider?.debug_data[3][2]}
        
        />
         <ChartData
          label={`${text3 || ""}`}
          color={data?.color}
          value={range_slider?.debug_data[2][2]}
         
        />
        
         <ChartData
          label={`${text5 || ""}`}
          color={data?.color}
          value={range_slider?.debug_data[4][2]}
       
        />

       
      </div>
      <div className="rarity-gemological-footer">
        <p className="m-0">
          Any data acquired from the FCRF Rarity Calculator is not a guarantee
          or valuation. The Argyle rarity figures were collected by the FCRF
          from open sources and do not represent any official information
          provided by Rio Tinto. For additional information regarding data
          disclaimers or limitations, please visit www.fcresearch.org or contact
          us at: customers@fcresearch.org
          <br />
          <span>Report generated on  {formatDateReport(report?.timestamp)}</span>
        </p>
      </div>
      <div className="rarity-tool-right-mobile-view">
        <DiamondRare report={report}  colorCode={getColorCode(data?.color)} />
      </div>
    </div>
  );
};

export default GemologicalCharacteristics;
