export const FamousDiary = [
    {
      id: 1,
      color: "Fancy Light Pink",
      img: ["AgraDiamond1", "AgraDiamond2"],
      title: "Another diamond graded as Fancy Light Pink:",
      desc: [
        "#The Agra Diamond# was graded a Fancy Light Pink by the GIA in 1990 and is one of the most famous diamonds in the world. It was worn on the turban of Babur, the first Mughal emperor, after he took possession of the city of Agra in 1526.",
      ],
    },
    {
      id: 2,
      color: "Fancy Intense Yellow",
      img: ["AllnattDiamond"],
      title: "Another diamond graded as Fancy Intense Yellow:",
      desc: [
        "The 101.29 carat #Allnatt Diamond# is a Cushion-cut diamond that is one of the most famous Fancy Intense Yellow diamonds in the world. It used to belong to Alfred Ernest Allnatt, an English businessman and patron of the arts.",
      ],
    },
    {
      id: 3,
      color: "Fancy Pink",
      img: ["GrandCondé1", "GrandCondé2"],
      title: "Another diamond graded as Fancy Pink:",
      desc: [
        "#The Grand Condé# is an exquisite Pear-shaped Fancy Pink diamond weighing 9.01 carats.",
        "The royal Condé family formed a significant branch of the French Bourbon dynasty. The most renowned member, Louis II (1621-1686), also known as 'the Grand Condé', took on the role of governor of the Bourgogne region at just 17 years old. Louis II led France to victory against Spain in the Battle of Rocroi in 1634, marking the end of the Thirty-Year War. After this battle, Louis XIII awarded Louis II the splendid Grand Condé diamond as a token of appreciation.",
      ],
    },
    {
      id: 4,
      color: "Green",
      img: ["DresdenGreenDiamond"],
      title: "Another diamond graded as Green:",
      desc: [
        "#The Dresden Green Diamond#, also known as the Dresden Green, is a 41-carat (8.2 g) natural green diamond that originated in the mines of India. The Dresden Green is a rare Type IIa, and is the most famous green fancy color diamond in the world",
      ],
    },
    {
      id: 5,
      color: "Fancy light yellow",
      img: ["VainerBrioletteDiamond"],
      title: "Another diamond graded as Fancy light yellow:",
      desc: [
        "#The Vainer Briolette Diamond# weighs 116.60 carats and has 192 facets. The GIA certified the diamond’s polish and symmetry as Excellent and its color as Fancy Light Yellow. The Vainer Briolette is the second-largest Briolette-cut diamond in the world, and enjoys the distinction of being the largest diamond faceted in London since the Regent.",
      ],
    },
    {
      id: 6,
      color: "Fancy Deep Blue",
      img: ["ShirleyTempleBlue1", "ShirleyTempleBlue2"],
      title: "Another diamond graded as Fancy Deep Blue:",
      desc: [
        "#The Shirley Temple Blue# was a 9.54 carat diamond Fancy Deep Blue Cushion-cut diamond bought by actress Shirley Temple’s father in 1940. It was later recut to make it Internally Flawless.",
      ],
    },
    {
      id: 7,
      color: "Fancy Vivid Blue",
      img: ["OppenheimerBlue1", "OppenheimerBlue2"],
      title: "Another diamond graded as Fancy Vivid Blue:",
      desc: [
        "#The Oppenheimer Blue# is a 14.62-carat Fancy Vivid Blue Emerald-cut diamond, named after its previous owner, Sir Philip Oppenheimer, whose family controlled 80% of the world’s diamond mining through De Beers.",
      ],
    },
    {
      id: 8,
      color: "Fancy Grayish Blue",
      img: ["MarieAntoinette1", "MarieAntoinette2"],
      title: "Another diamond graded as Fancy Grayish Blue:",
      desc: [
        "The Fancy Grayish Blue diamond of Marie-Antoinette weighs 5.46 carats and was brought with her from Vienna. It was set in a ring as a gift to the Polish Princess Lubomirska in 1791 and later passed on to the noble Potocki family.  The diamond was exhibited in Paris in 1892 and again at the Exposition Universelle in 1900. Half a century later, it featured in the 1955 Marie-Antoinette exhibition at Versailles.",
      ],
    },
    {
      id: 9,
      color: "Deep Grayish Blue",
      img: ["HopeDiamond"],
      title: "Another diamond graded as Fancy Deep Grayish Blue:",
      desc: [
        "#The Hope Diamond#, a 45.52-carat fancy deep grayish-blue diamond, is one of the most famous diamonds in the world. It was first sold to King Louis XIV of France and has passed through many famous owners over the years. Today, it is displayed at the Smithsonian Museum after being donated by Harry Winston in 1958.",
      ],
    },
    {
      id: 10,
      color: "Fancy Blue",
      img: ["TereschenkoDiamond1", "TereschenkoDiamond2"],
      title: "Another diamond graded as Fancy Blue.",
      desc: [
        "#The Tereschenko Diamond# weighs 49.92 carats and is believed to be of Indian origin. The diamond was first owned by the Tereschenko family of Russia. In around 1913, the diamond was set into a necklace by Cartier with 46 other notable diamonds of various colors, shapes, and sizes. It was eventually reunited with its owner in Paris in 1916. The location of the Tereschenko Blue Diamond remained a mystery for several decades until it reappeared at a Christie’s auction in Geneva in 1984.",
      ],
    },
    {
      id: 11,
      color: "Light Pink",
      img: ["LightPinkOvaldiamond1", "LightPinkOvaldiamond2"],
      title: "Another diamond graded as Light Pink",
      desc: [
        "In 2012, celebrity couple Blake Lively and Ryan Reynolds announced their upcoming marriage with a 12-carat Light Pink Oval diamond engagement ring.",
      ],
    },
    {
      id: 12,
      color: "Fancy Brown Orange",
      img: ["Fancy_Brown-Orange"],
      title: "Another diamond graded as Fancy Brown Orange",
      desc: [
        "Rihanna was seen at the 2023 Oscars ceremony wearing a rare matching pair of fancy orange-brown diamonds. The diamonds weigh 35.82 and 35.66 carats each and were mounted in earrings.",
      ],
    },
    {
      id: 13,
      color: "Fancy Vivid	Orange",
      img: ["Fancy-Vivid_Orange"],
      title: "Another diamond graded as Fancy Vivid Orange",
      desc: [
        "In 2002, actress Halle Berry wore the ring to the 74th Academy Awards. She displayed a a 5.54 ccarat fancy vivid Orange cushion shape  known as the Pumpkin Diamond on her pinkie when she gave her memorable acceptance speech as the first African American to win the golden statue for Best Actress.",
      ],
    },
    {
      id: 14,
      color: "Fancy Vivid	Yellow",
      img: ["Fancy-Vivid_Yellow"],
      title: "Another diamond graded as Fancy Vivid Yellow",
      desc: [
        "Kate winslet with a set of vivid yellow radiant cut diamonds by Tiffany & Co, ranging from 4 to 7 carat each in the 2010 Oscar’s ceremony",
      ],
    },
    {
      id: 15,
      color: "Fancy Intense	Yellow Green",
      img: ["Fancy-Intense_ Yellow-Green"],
      title: "Another diamond graded as Fancy Intense Yellow Green",
      desc: [
        "The famous 6.10 carat fancy intense Yellow Green modified cushion Jenifer Lopez received from Ben Affleck in 2022 when he proposed for the second time.",
      ],
    },
  ];