import React, { useEffect, useState } from 'react'

const ScrollToTop = ({scrollClass}) => {
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const handleScroll = () => {
        const scrollY = window.scrollY;
        if (scrollY > 100) {
          setIsButtonVisible(true);
        } else {
          setIsButtonVisible(false);
        }
      };
    
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };
      useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);
  return (
    <div
          className={`${scrollClass}  ${isButtonVisible?"":"d-none"}`}
        >
          <span onClick={scrollToTop}>
            {" "}
            <ArrowUp />
          </span>
        </div>
  )
}

export default ScrollToTop

const ArrowUp = () => {
    return (
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_626_167)">
          <circle cx="21" cy="17" r="17" fill="#171F37" />
          <path
            d="M22.2664 24.3335H20.2462V12.2123L14.6907 17.7678L13.2563 16.3335L21.2563 8.3335L29.2563 16.3335L27.822 17.7678L22.2664 12.2123V24.3335Z"
            fill="#BF8F02"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_626_167"
            x="0"
            y="0"
            width="42"
            height="42"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_626_167"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_626_167"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  };