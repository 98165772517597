import "./pageLoader.css";


const PageLoader = () => {
  return (
    <div className="loading-screen page-loader">
      <div className="three-body">
<div className="three-body__dot"></div>
<div className="three-body__dot"></div>
<div className="three-body__dot"></div>
</div>
    </div>
  );
};

export default PageLoader;
