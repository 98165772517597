import React from "react";
const RarityInfo = ({ range_slider }) => {
  return (
    <div className="rarity-card-content rarity-level grade-desktop-view">
      <div className="rarity-card-content rarity-gemological-card-mb-scnd">
        <h6>Rarity</h6>
        <p className="rar-customized-para ">Denomination brakets</p>
      </div>

      <ul className="m-0 rarity-level-left-padding" >
      <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Notable" ? "remarkable-bold" :""} rarity-level-first-para`} >Notable</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end text-nowrap">1300-10,000 yearly</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Outstanding" ? "remarkable-bold" :""} rarity-level-first-para`}>Outstanding</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end text-nowrap">400-1300.....yearly</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Special" ? "remarkable-bold" :""} rarity-level-first-para`}>Special</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end text-nowrap">180-400........yearly</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Very Special" ? "remarkable-bold" :""} rarity-level-first-para`}>Very Special</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end text-nowrap">40-180...........yearly</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Extraordinary" ? "remarkable-bold" :""} rarity-level-first-para`}>Extraordinary</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end text-nowrap">10-40..............yearly</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Rare" ? "remarkable-bold" :""} rarity-level-first-para`}>Rare</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end text-nowrap">3-10........every year</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Very Rare" ? "remarkable-bold" :""} rarity-level-first-para`}>Very Rare</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end text-nowrap">1-3..........every year</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim()=== "Extremely Rare" ? "remarkable-bold" :""} rarity-level-first-para`}>Extremely Rare</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end text-nowrap">1x........every 1 - 12y</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Exceptional" ? "remarkable-bold" :""} rarity-level-first-para`}>Exceptional</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end text-nowrap">1x......every 13 - 24y</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between ">
            <p className={`${range_slider?.rarity_degree?.trim() === "Remarkable" ? "remarkable-bold" :""} rarity-level-first-para`}>Remarkable</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end text-nowrap">1x...............every 25y</p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default RarityInfo;
