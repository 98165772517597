/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, useMemo } from "react";
import Chart from "react-apexcharts";

const weightChartData = [
  "This diamond's weight category",
  "Diamonds with lower weight",
  "Diamonds with higher weight",
];

const DiaryWeightPDF = ({
  weightInfo,
  color,
  sidebarCard,
  handleSideBarCard,
  isHide,
  weightCategory,
}) => {
  const weightBox = useRef(null);
  const [fillValue, setFillValue] = useState(0);
  const [fillValueSecondary, setFillValueSecondary] = useState(100);
  const [viewOnce, setViewOnce] = useState(false);

  const isInViewport = useIsInViewport(weightBox);

  const weightTitleTxt = weightInfo?.B32?.split("<br>") || [];
  const weightText = weightInfo?.C34?.split("#") || [];
  const weightFillValue = weightCategory?.D8?.split("%")?.[0];
  const weightFillSecondValue = weightCategory?.F8?.split("%")?.[0];
  setTimeout(() => {
    setFillValue(viewOnce ? Number(weightFillValue) : 0);
    setFillValueSecondary(viewOnce ? Number(weightFillSecondValue) : 100);
    if (isInViewport) {
      setViewOnce(true);
    }
  }, 500);

  let bgValue = 100 - (fillValue + fillValueSecondary);

  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }

  function colorOpacity(color) {
    return `${color}b3`;
  }

  function colorHalfOpacity(color) {
    return `${color}80`;
  }

  const toolTipColors = [color, colorHalfOpacity(color), colorOpacity(color)];

  const chartData = {
    series: [fillValue, fillValueSecondary, bgValue],
    options: {
      stroke: {
        show: false,
      },
      chart: {
        id: "weight-graph-pdf",
        type: "pie",
        width: "100%",
        fontFamily: "Montserrat, sans-serif",
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: [
            fillValue >= 7 ? "#fff" : "#ffffff00",
            fillValueSecondary >= 7 ? "#000" : "#00000000",
            bgValue >= 7 ? "#fff" : "#ffffff00",
          ],
          fontSize: "16px",
          fontFamily: "Montserrat",
          fontWeight: "400",
        },
        dropShadow: {
          enabled: false,
        },
      },
      fill: {
        colors: [color, colorHalfOpacity(color), colorOpacity(color)],
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: false,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
            offset: -16,
            minAngleToShowLabel: 10,
          },
          donut: {
            size: "100%",
            background: "#e5e7ef",
            labels: {
              show: false,
            },
          },
        },
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div className="weight-chart-ex" style="display:flex; align-items:center; justify-content:space-between;">' +
            `<div className="chart-color" style= 'background-color:${toolTipColors[seriesIndex]}; width:16px; height: 16px; border-radius:16px;'></div>` +
            "<p style='width: calc( 100% - 26px )'>" +
            `${series[seriesIndex].toFixed(1)}% <br/> ${
              weightChartData[seriesIndex]
            }` +
            "</p>" +
            "</div>"
          );
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <section
      className={`diary-card ${sidebarCard?.weightBox ? "show" : "hide"}`}
      style={{ opacity: 0, appearance: "none", position: "fixed", zIndex: -1 }}
    >
      {!isHide && (
        <div className="mobile-hide-card-btn">
          <label className="diary-check-container">
            <input
              type="checkbox"
              id={`nav-weightBox`}
              name={`weightBox`}
              onChange={() =>
                handleSideBarCard("weightBox", sidebarCard?.weightBox)
              }
              checked={sidebarCard?.weightBox}
            />
            <div className="checkmark"></div>
          </label>
        </div>
      )}
     
      <h4>
        {weightTitleTxt[0]}
        <br />
        {weightTitleTxt[1]}
      </h4>
      <div className="diary-card-content carat-card-content">
        <div className="carat-heading">
         
          <p>
            {weightText[0]}
            <b>{weightText[1]}</b>
            {weightText[2]}
            <b>{weightText[3]}</b>
            {weightText[4]}
            <b>{weightText[5]}</b>
            {weightText[6]}
          </p>
        </div>
        <div className="carat-chart-wrapper" ref={weightBox}>
          <Chart
            options={chartData.options}
            type="pie"
            series={chartData.series}
            width="100%"
            height="300px"
          />
        </div>
      </div>
    </section>
  );
};

export default DiaryWeightPDF;
